.multi-image {
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;

  .multi-image-wrapper {
    @include bp(sm-md, max) {
      width: 100%;
      //overflow: auto;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      height: 422px;
    }

    >.multi-image-row {
      @include bp(sm-md, max) {
        width: 100%;
        min-width: 1100px;
        position: absolute;
      }
    }
  }


  .multi-image-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;

    .multi-image-column {
      padding-left: 12px;
      padding-right: 12px;

      .full-height {
        height: 100%;
      }

      .image-wrapper {
        width: 100%;
        overflow: hidden;
        position: relative;

        &.animate-from-top {
          animation: slideFromTop 0.85s;
          @include image-slide(slideFromTop, -50%);
        }

        &.animate-from-bottom {
          animation: slideFromBottom 0.85s;
          @include image-slide(slideFromBottom, 50%);
        }


        &:nth-child(2) {
          margin-top: 20px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 20px;

          &.equal-size-image {
            height: 232px;

            @include bp(md-lg, max) {
              height: 195px;
            }

            @include bp(md, max) {
              height: 155px;
            }

            @include bp(sm-md, max) {
              height: 182px;
            }

          }
        }
      }
    }
  }

  .column1 {
    width: 26%;
  }

  .column2 {
    width: 30%;
  }

  .column3 {
    width: 44%;
  }

  .column-50 {
    width: 50%;
  }
}