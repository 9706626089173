
.secondary-messaging-cards-container {
  width: 100%;

  .cards-wrapper {
    margin: 0 auto;
    max-width: $max-width;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 72px 0 32px 0;

    &.qkn-light-blue-gradient {
      background: linear-gradient(to bottom,  var(--qkn-light-blue) 0%, var(--qkn-light-blue) calc(50% + 2vw), #ffffff calc(50% + 2vw), #ffffff 100%);
      @include bp(lg, max) {
        background: linear-gradient(to bottom,  var(--qkn-light-blue) 0%, var(--qkn-light-blue) calc(75% + 2vw), #ffffff calc(75% + 2vw), #ffffff 100%);
      }
      @include bp(md, max) {
        background: linear-gradient(to bottom,  var(--qkn-light-blue) 0%, var(--qkn-light-blue) calc(60% + 2vw), #ffffff calc(60% + 2vw), #ffffff 100%);
      }
      @include bp(md, max) {
        background: linear-gradient(to bottom,  var(--qkn-light-blue) 0%, var(--qkn-light-blue) calc(60% + 2vw), #ffffff calc(60% + 2vw), #ffffff 100%);
      }
      @include bp(sm-md, max) {
        background: linear-gradient(to bottom,  var(--qkn-light-blue) 0%, var(--qkn-light-blue) calc(40% + 2vw), #ffffff calc(40% + 2vw), #ffffff 100%);
      }
    }
    &.qkn-light-blurple-gradient {
      background: linear-gradient(to bottom,  var(--qkn-mid-blue-background) 0%, var(--qkn-mid-blue-background) calc(50% + 2vw), #ffffff calc(50% + 2vw), #ffffff 100%);
      @include bp(lg, max) {
        background: linear-gradient(to bottom,  var(--qkn-mid-blue-background) 0%, var(--qkn-mid-blue-background) calc(75% + 2vw), #ffffff calc(75% + 2vw), #ffffff 100%);
      }
      @include bp(md, max) {
        background: linear-gradient(to bottom,  var(--qkn-mid-blue-background) 0%, var(--qkn-mid-blue-background) calc(60% + 2vw), #ffffff calc(60% + 2vw), #ffffff 100%);
      }
      @include bp(md, max) {
        background: linear-gradient(to bottom,  var(--qkn-mid-blue-background) 0%, var(--qkn-mid-blue-background) calc(60% + 2vw), #ffffff calc(60% + 2vw), #ffffff 100%);
      }
      @include bp(sm-md, max) {
        background: linear-gradient(to bottom,  var(--qkn-mid-blue-background) 0%, var(--qkn-mid-blue-background) calc(40% + 2vw), #ffffff calc(40% + 2vw), #ffffff 100%);
      }
    }

    .title-wrapper {
      width: 700px;
      margin-left: 20px;
      margin-right: 20px;
      @include bp(lg, max) {
        width: auto;
        margin-left: 120px;
        margin-right: 120px;
      }
      @include bp(md, max) {
        width: auto;
        margin-left: 60px;
        margin-right: 60px;
      }
    }
    .cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      padding-top: 44px;
      width: 100%;
      @include bp(lg, max) {
        justify-content: center;
      }

      .hero-image {
        flex-grow: 2;
        @include bp(lg, max) {
          order: -1;
          flex-grow: 4;
          flex-basis: 100%;
        }

        img {
          width: 350px;
          height: auto;

          @include bp(sm-md) {
            width: 400px;
          }

          @include bp(md) {
            width: 358px;
          }
        }
      }

      .simple-card {
        display: flex;
        flex-direction: column;
        border-radius: $border-radius-default;
        border: 1px solid var(--qkn-medium-gray2);
        margin: 20px;
        padding: 20px 0 20px 0;
        width: 208px;
        max-height: 275px;
        overflow: hidden;

        @include bp(md, max) {
          width: 350px;
        }

        h4 {
          padding: 20px 20px 0 20px;
          text-align: left;
          height: 70px;
          font-size: 19px;
          font-weight: 600;
          box-sizing: content-box;
          line-height: 135%;
          margin-bottom: 10px;
          @include bp(md, max) {
            height: inherit;
          }
        }
        .image {
          width: 100%;
          text-align: initial;
        }

        span {
          text-align: left;
          padding-left: 20px;
          padding-right: 20px;
          color: var(--qkn-light-gray);
          font-size: 16px;
          font-weight: 400;
          line-height: 140%;
        }
      }
    }
  }
}