.price-section {
  color: var(--qkn-medium-gray);
  line-height: 1.4;

  .pre-text {
    grid-area: preText;
    font-size: 17px;
    padding-right: 5px;
  }
  .price {
    grid-area: price;
    font-size: 17px;
    font-weight: 600;
  }
  .price-cents {
    font-size: 17px;
    font-weight: 600;
  }
  .billed {
    padding-left: 5px;
  }
  .full-price {
    grid-area: fullPrice;
    text-decoration-line: line-through;
    -webkit-text-decoration-line: line-through;
    text-decoration-color: red;
    -webkit-text-decoration-color: red;
    display: none;
    &.show-price {
      display: inline;
    }
    s {
      color: var(--qkn-strike-red);
      span {
        color: var(--qkn-medium-gray);
      }
    }
  }
  .percentage {
    grid-area: percentage;
    color: var(--white);
    background-color: var(--qkn-strike-red);
    font-size: 13px;
    border-radius: 20px;
    display: none;
    width: 67px;
    justify-content: center;
    margin-right: 0.5em;
    &.show-price {
      display: inline-flex;
    }
    &::after {
      content: '†';
      margin-left: 0.15em;
      margin-top: -0.15em;
      vertical-align: super;
    }
    &.no-after:after{
      content:none;
    }
  }
  .per {
    grid-area: per;
    font-weight: 600;
    padding-right: 5px;
  }

  &.inline {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include bp(md) {
      justify-content: start;
    }

    .full-price {
      padding-right: 5px;
    }

    .price {
      font-weight: 600;
    }
    .per {
      font-weight: 600;
      padding-left: 5px;
    }
    .billed {
      padding-right: 5px;
      padding-left: 0;
    }
  }
  &.stacked {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 40% 40% 20%;
    grid-template-areas:
      "price decimal priceCents fullPrice percentage . . ."
      "price . . per per . . ."
      "billed billed billed billed billed billed billed billed";

    .decimal,
    .price-cents,
    .full-price,
    .percentage {
      justify-self: center;
      align-self: center;
      margin-top: 15px;
    }
    .price-cents,
    .decimal {
      font-size: 18px;

      @include bp(sm-md) {
        font-size: 24px;
      }
    }
    .pre-text {
      display: none;
      visibility: hidden; //for css grid to not setup columns for this elements with auto
    }
    .per {
      color: var(--eerie-black-opacity);
      justify-self: start;
    }
    .percentage {
      display: none;
      justify-content: center;
      align-items: center;
      margin-left: 6px;

      &.show-price {
        display: flex;
      }
    }
    .price {
      font-size: 43px;
      font-weight: 600;
      align-self: self-end;

      @include bp(sm-md) {
        font-size: 55px;
      }
    }
    .price-cents {
      grid-area: priceCents;
      font-weight: 600;
    }
    .decimal {
      grid-area: decimal;
      font-weight: 600;
    }
    .full-price {
      margin-left: 10px;
      color: var(--eerie-black-opacity);
      text-decoration-line: line-through;
      -webkit-text-decoration-line: line-through;
      text-decoration-color: red;
      -webkit-text-decoration-color: red;

      s span {
        color: var(--eerie-black-opacity);
      }
    }
    .billed {
      grid-area: billed;
      font-size: 13px;
      color: var(--qkn-light-gray-opaque)
    }
  }
}
