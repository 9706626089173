.support-topic-wrap {
  display: flex;

  flex-direction: column;
  @include bp(sm-md) {
    flex-direction: row; 
  } 

  flex-wrap: wrap; 
  justify-content: space-between;
  min-height: 500px; 
 
  .support-topic-main {
    flex-basis: 100%; 
    @include bp(mobile) {
      flex-basis: 70%; 
    }

    padding: 21px; 

    .topic-landing-section {
      .topic-links-tiles { 
        width: 95%; 
        @include bp(sm) {
          width: unset; 
        }
        
        margin-top: 34px; 
 
        .topic-links-tile {
          width: 100%; 

          &.qkn-lightblue-background {
            width: fit-content; 
          }

          border: solid 1px var(--qkn-medium-gray2); 
          margin-bottom: 13px; 
          border-radius: 22px; 
          padding: 21px; 
 
          .topic-links-tile--links {
            display: flex;
            flex-wrap: wrap;
            
            .topic-link-item {
              flex-basis: 100%; 
              
              // flex-basis: 98%; 
              // @include bp(mobile) {
              //   flex-basis: 45%; 
              // }
              // @include bp(md) {
              //   flex-basis: 100%; 
              // } 

              display: flex; 
              flex-direction: row;
              align-items: center;
  
              padding: 13px;
  
              img.topic-link-icon {
                width: 21px; 
                height: auto; 
                margin-right: 13px; 
              } 
  
              a {
                color: var(--qkn-blurple); 
              }
            }
          }
        }
      }
    }
  }
  
  .support-topic-right-sidebar {
    flex-basis: 100%; 
    @include bp(mobile) {
      flex-basis: 30%;
    }

    .topic-landing-section {
      width: 100%;      

      .topic-links-tiles { 
        margin-top: 34px; 
        padding: 21px; 

        .topic-links-tile {
          margin-bottom: 13px; 
          padding: 21px; 
 
          .topic-link-item {
            display: flex; 
            flex-direction: row;
            align-items: center;
            padding: 13px 0 13px 0;

            img.topic-link-icon {
              width: 21px; 
              height: auto; 
              margin-right: 13px; 
            } 

            a {
              color: var(--qkn-blurple); 
            }
          }
        }
      }
    }
  }
}

