#full-page-cards {
  background: linear-gradient(var(--qkn-mid-blue-background) 40%, transparent 40%) no-repeat;
  
  @include bp(sm-md) {
    background: linear-gradient(var(--qkn-mid-blue-background) 600px, transparent 600px) no-repeat;
  }
}

#full-page-cards,
#selector-cards {
  &.qkn-mid-blue-background {
    .pcard-contain {
      background-color: var(--white);
    }
  }

  .sl-contain {
    max-width: 250px;
    padding: 30px 0px;

    @include bp(sm-md) {
      max-width: unset;
    }
  }

  .cards {
    flex-direction: column;
    width: 100%;
    max-width: 864px;

    @include bp(864px, max) {
      padding: 0px 20px;
    }

    .product-card {
      &.simplifi-purple-theme {
        border: 3px solid var(--qkn-blurple, #471CFF);
      }

      &.classic-red-theme {
        border: 3px solid var(--qkn-border-gray);
      }

      &.inactive-card {
        border: 3px solid var(--qkn-border-gray, #ECEDF1);
      }

      .price-contain {
        padding: 25px 28px;
      }

      .flex-wrap {
        @include bp(sm-md) {
          position: relative;
          flex-direction: row;

          .flex-right,
          .flex-left {
            width: 50%;
          }

          .card-break {
            display: none;

            @include bp(sm-md) {
              display: block;
              position: absolute;
              top: 10%;
              left: 50%;
              height: 80%;
              border-right: 1px solid var(--qkn-medium-gray2);
            }

          }
        }
      }
    }
  }

  .cta {
    display: flex;
    justify-content: center;
    padding-top: 25px;

    .cta-btn {
      border: 2px solid var(--qkn-medium-gray2);
      a {
        padding: 20px 30px;
      }

      &:hover {
        a {
          color: var(--white);
        }

        background-color: var(--qkn-blurple);
        border-color: var(--qkn-blurple);
      }

      a {
        font-weight: 600;
        font-size: 17px;

        @include bp(sm-md) {
          font-size: 20px;
        }
      }
    }
  }
}