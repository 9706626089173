// styles for the multi card slider
// .multicard-styles.splide,
.multicard-slider.splide {
  display: grid;
  grid-template-areas:
      "header"
      "slider"
      "arrows"
      "cta";

  // &.rc-slider {
  //   grid-template-areas:
  //     "header arrows"
  //     "slider slider"
  //     "cta cta";
  // }

  @include bp(md) {
    max-width: 1150px;
    margin: 0 auto;
    grid-template-areas:
      "header arrows"
      "slider slider"
      "cta cta";
  }

  .slider-header {
    grid-area: header;
    margin-bottom: 56px;

    /* Put arrow buttons on right if there's no title in the header element, overrides space-between */
    &:has(> *:only-child) {
      justify-content: flex-end;
    }

    @include bp(lg) {
      padding: 0;
    }
  }

  .splide__arrows {
    grid-area: arrows;
    justify-self: center;
    margin-top: 32px;

    @include bp(md) {
      justify-self: flex-end;
      margin-top: unset;
      display: flex;
      gap: 30px;
      margin-bottom: 56px;
    }

    // &.rc-slide-arrows {
    //   display: flex;
    //   gap: 10px;
    //   padding-left: 10px;
    //   margin-bottom: 40px;

    //   @include bp(640px) {
    //     padding-left: 10.5%;
    //   }

    //   @include bp(md) {
    //     margin-bottom: 0;
    //     padding-left: 0;
    //     gap: 0px;
    //   }
    // }

    .splide__arrow {
      display: flex;
      border-radius: 100%;
      position: unset;

      @include bp(lg) {
        display: inline-block;

        img {
          margin-top: 5px;
        }
      }

      @include bp(md, max) {
        display: inline-block;
        width: 48px;
        height: 48px;

        img {
          margin-top: 5px;
        }
      }
    }

    // .splide__arrow.splide__arrow--prev,
    // .splide__arrow.splide__arrow--next {
    //   @include bp(md, max) {
    //     &.rc-slider-arrow {
    //       position: initial;
    //       margin: 0;
    //     }
    //   }
    // }

    .splide__arrow.splide__arrow--next:enabled:hover,
    .splide__arrow.splide__arrow--prev:enabled:hover {
      background: var(--qkn-blurple, #471CFF);

      img {
        filter: invert(1);
      }
    }
  }

  // Arrow Tweaks
  .splide__track {
    grid-area: slider;
    @include bp(lg, max) {
      margin-bottom: 10px;
    }
  }

  .cta {
    grid-area: cta;
    display: flex;
    justify-content: center;
  }
}