.top-banner {
    z-index: 0;
    padding: 0;
    position: relative;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    height: 88px;

    @include bp(389px, max) {
      height: auto;
    }

    @include bp(sm-md) {
      height: 102px;
    }

    @include bp(md) {
      height: 112px;
    }

    .banner-img {
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      max-width: 50%;

      @include bp(390px) {
        max-width: 25%;
      }

      @include bp(sm-md) {
        max-width: 30%;
      }

      @include bp(lg) {
        max-width: unset;
        width: 40%;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: right;
      }
    }

    .top-banner-inner {
      width: 100%;
      display: grid;
      align-items: center;
      padding-top: 18px;
      grid-template-areas: 
        'titleWrap brandLogo'
        'ctaBtn    brandLogo'
        'ctaBtn .';

      @include bp(389px, max) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        grid-template-areas:
          'titleWrap'
          'brandLogo'
          'ctaBtn';
      }

      @include bp(sm-md) {
        padding-top: 0px;
        grid-template-columns: 200px 200px 1fr;
        grid-template-areas:
          'titleWrap brandLogo ctaBtn';
      }

      @include bp(md) {
        padding-top: 0px;
        grid-template-columns: 330px 330px 1fr;
        grid-template-areas:
          'titleWrap brandLogo ctaBtn';
      }


      .title-wrap h1,
      .title-wrap h2,
      .title-wrap h3,
      .title-wrap h4,
      .title-wrap h5,
      .title-wrap h6,
      .title-wrap p {
        font-size: 13px;
        line-height: 1;
        font-weight: 600;

        @include bp(md) {
          font-size: 24px;
        }
      }

      .title-wrap {
        grid-area: titleWrap;
        width: 140px;
        align-self: flex-start;
    
        @include bp(sm-md) {
          width: unset;
          padding-left: 25px;
          align-self: center;
        }
      }

      .brand-logo {
        grid-area: brandLogo;
        align-self: flex-start;

        @include bp(390px, max) {
          padding-left: 0px;
        }

        @include bp(sm-md) {
          border-left: 1px solid rgb(71 28 255 / 10%);
          align-self: center;
          padding-left: 25px;
        }

        img {
          width: 110px;
          @include bp(md) {
            width: 153px;
          }
        }
      }

      .cta-btn {
        @include bp(sm-md, max) {
          padding: 8px 20px;
          font-size: 12px;
          max-width: 100px;
        }

        @include bp(390px, max) {
          margin-top: 0px;
          align-self: flex-start;
          margin-bottom: 5px;
        }

        @include bp(sm-md) {
          margin-right: 32px;
          justify-self: flex-end;
        }

        a {
          @include bp(sm-md) {
            font-size: 17px;
          }

          @include bp(sm-md, max) {
            padding: unset;
          }
        }
      }
    }

    .close-banner {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 14px;
      &:hover {
        cursor: pointer;
      }
    }
}