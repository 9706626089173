// styles for the single card slider
.save-slider.splide {
  margin-bottom: 50px;

  @include bp(md) {
    margin-bottom: 0px;
  }

  .cta {
    display: flex;
    justify-content: center;
  }

  // slide settings for deactivated state
  .splide__slide {
    .testimonial {
      background-color: var(--card-gray, #F0F5FA);

      @include bp(md) {
        .logo_image {
          opacity: 0;
          transition: opacity 600ms;
        }

        .testimonial-quote {
          opacity: 0;
          transition: opacity 600ms;
        }

        .testimonial-author {
          opacity: 0;
          transition: opacity 600ms;
        }

        .quotation-mark-1 {
          opacity: 0;
          transition: opacity 600ms;
        }
      }
    }
  }

  .splide__slide.is-active {
    .testimonial {
      background-color: var(--card-gray, #F0F5FA);

      .logo_image {
        opacity: 1;
      }

      .testimonial-quote {
        opacity: 1;
      }

      .testimonial-author {
        opacity: 1;
      }

      .quotation-mark-1 {
        opacity: 1;
      }
    }

  }

  .splide__arrows {
    @include bp(md, max) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      margin-bottom: -50px;
      height: 50px;
    }
  }

  // Arrow Tweaks
  .splide__arrow {
    top: 45%;
    bottom: 0;
    height: 64px;
    width: 64px;
    transform: none;
    border-radius: 100%;
    opacity: 1;

    svg {
      filter: invert(1);
      width: 24px;
      height: 24px;
    }

    border: 1px solid;
    border-color: var(--bright-gray, #EAECF7);
    filter: drop-shadow(6px 9px 26px rgba(7, 8, 31, 0.06));

    @include bp(md, max) {
      position: absolute;
      display: inline-block;
      border: none;
      height: 48px;
      width: 48px;
      top: 98%;
      filter: none;

      img {
        margin-top: 5px;
      }
    }
  }

  .splide__arrow.splide__arrow--prev {
    left: 100px;
    right: auto;
    background: var(--white, #FFF);
    transform: scaleX(-1);

    @include bp(1300px, max) {
      left: 17px;
    }

    @include bp(md, max) {
      position: absolute;
      background: var(--card-gray, #F0F5FA);
      left: 50%;
      margin-left: -60px;
    }
  }

  .splide__arrow.splide__arrow--next {
    right: 100px;
    background: var(--white, #FFF);

    @include bp(1300px, max) {
      right: 17px;
    }

    @include bp(md, max) {
      background: var(--card-gray, #F0F5FA);
      left: calc(50% + 10px);
    }
  }

  .splide__arrow.splide__arrow--prev:disabled {
    opacity: 0.5;
  }

  .splide__arrow.splide__arrow--next:disabled {
    opacity: 0.5;
  }

  .splide__arrow:hover {
    background: var(--qkn-blurple, #471CFF);

    img {
      filter: invert(1);
    }
  }

  .testimonial {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 360px;
    background-color: var(--qkn-blurple, #471CFF);
    border-radius: $border-radius-default;
    position: relative;
    margin: auto;

    @include bp(md, max) {
      min-height: 330px;
      height: auto;
    }

    .logo_image {
      align-self: flex-start;
      position: relative;
      top: 0px;
      border-radius: $border-radius-default 0px 0px $border-radius-default;

      @include bp(md, max) {
        display: none;
      }
    }
  }

  .testimonial-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .quote-container {
    display: flex;
    flex-direction: row;

    @include bp(md, max) {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
  }

  .quotation-mark-1 {
    position: relative;
    left: -6px;
    top: -5px;
    height: 50px;
    width: 10px;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.36px;

    @include bp(md, max) {
      margin-left: auto;
      margin-right: auto;
      left: -3px;
      top: 0;
      font-size: 22px;
      line-height: 135%;
      letter-spacing: -0.095px;

      @include bp(639px, max) {
        font-size: 19px;
      }
    }
  }

  .testimonial-quote {
    position: relative;
    font-size: 22px;
    height: auto;
    margin-right: 50px;
    color: var(--qkn-medium-gray);
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.22px;

    @include bp(md, max) {
      margin-right: 0;
      margin-top: 0px;
      left: 0px;
      font-size: 22px;
      line-height: 135%;
      letter-spacing: -0.095px;

      @include bp(639px, max) {
        font-size: 19px;
      }
    }

    * {
      display: inline;
    }

    .quotation-mark-2 {
      position: absolute;
      margin-top: -4px;
      margin-left: 5px;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.36px;

      @include bp(md, max) {
        font-size: 22px;
        line-height: 135%;
        letter-spacing: -0.095px;

        @include bp(639px, max) {
          font-size: 19px;
        }
      }
    }

  }

  .testimonial-author {
    position: relative;
    gap: 16px;

    @include bp(md, max) {
      margin-left: auto;
      margin-right: auto;
      left: -3px;
      width: 80%;
    }
  }

  .testimonial-author>p {
    margin: 0;
    padding: 0;
  }

  .testimonial-author {
    .trustpilot_logo {
      width: 103px;
      height: auto;
    }
  }

  .testimonial-name {
    color: var(--qkn-blurple, #471CFF);
    font-weight: 600;
    width: 140px;
    height: 22px;
    text-align: left;
  }
}