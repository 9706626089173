.selector-options{
  h2 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 32px;
  }

  .options {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    
    gap: 4px;
    max-width: 350px;
    margin: 0 auto;
    padding: 0px 20px;


    @include bp(768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      max-width: 900px;
      padding: 0px 32px;
    }

    @include bp(md-lg) {
      max-width: 750px;
      gap: 24px;
    }

    @include bp(lg) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      max-width: 1098px;
    }

    .option > * {
      cursor: pointer;
    }

    .option {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      background-color: var(--white);
      padding: 12px;
      border: 1px solid var(--qkn-medium-gray2);
      border-radius: 4px;
      cursor: pointer;
      

      @include bp(md-lg) {
        border-radius: 1000px;
        padding: 16px 30px 16px 30px;

        .label {
          padding-right: 10px;
        }
      }

      .active-icon {
        display: none;
      }

      &.selected {
        color: var(--white);
        background-color: var(--qkn-bright-blurple);
        
        .reg-icon {
          display: none;
        }
        .active-icon {
          display: flex;
        }
      }
      img {
        height: 28px;
        width: 28px;
        margin-right: 12px;
      }

      .checkbox {
        display: none;
        height: 28px;
        width: 28px;

        .checked {
          display: none;

          &.active {
            display: block;
          }
        }

        .unchecked {
          display: none;
          
          &.active {
            display: block;
          }
        }

        @include bp(md-lg) {
          display: initial;
          margin-left: auto;
        }
      }
    }
  }
}