.support-questions-area {
  padding: 21px; 
  .support-questions-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; 
    .support-questions-tile {
      flex-basis: 98%; 

      @include bp(md) {
        flex-basis: 48%; 
      }

      @include bp(lg) {
        flex-basis: 32%; 
      }
 
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      padding: 20px;

      min-height: 250px;
      border: 1px solid #f0f5fa;
      border-radius: 20px;
      margin-bottom: 13px;

      .support-questions-header-row {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: start;
        margin-bottom: 13px; 

        .support-questions-icon {
          min-width: 40px;
          min-height: 40px;
          background: var(--light-purple2-40p); 
          border-radius: 9px;
          img {
            height: 20px;
            position: relative;
            top: 10px;
            left: 10px;
          }
        }

        .support-questions-header {
          padding-right: 15px;
          padding-top: 13px; 

          h4 {
            font-weight: 700; 
            font-size: 21px; 
            margin-bottom: unset; 
            color: var(--qkn-medium-gray); 
          }
        }
      }

      .support-questions-links {
        .support-questions-list {

          margin-bottom: 1rem;
          li {
            padding: 11px 0; 
            a {
              color: var(--qkn-light-gray); 
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

      }

    }
  }
}
