.compare-header,
.compare-table-body,
// .compare-table-body .row-group,
// .compare-table-body .row-subgroup,
.compare-footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  max-width: 546px;
  margin: 0 auto;

  @include bp(md) {
    max-width: 944px;
    grid-template-columns: 1fr 288px 288px;
  }

  div.product-col:nth-child(n+4),
  div.product-footer-col:nth-child(n+4),
  div.feature-data:nth-child(n+4),
  .prod-title.deluxe,
  .prod-title.business {
    display: none;
  }

  .product-col,
  .product-footer-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    text-align: center;
  }
}

.compare-header {
  grid-template-areas:
    "firstCol secondCol"
    "title title"
    "firstColTitle secondColTitle";

  &.show-premier {
    grid-template-areas:
      "firstCol thirdCol"
      "title title"
      "firstColTitle thirdColTitle";

      @include bp(md) {
        grid-template-areas:
          "title firstCol thirdCol"
          "blankCol firstColTitle thirdColTitle";
      }
  }

  &.show-business {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "thirdCol"
      "title"
      "thirdColTitle";
  }

  @include bp(md) {
    grid-template-areas:
      "title firstCol secondCol"
      "blankCol firstColTitle secondColTitle";
  }

  border-top-left-radius: $border-radius-default;
  border-top-right-radius: $border-radius-default;
  border-top: 1px solid var(--qkn-medium-gray2);
  border-left: 1px solid var(--qkn-medium-gray2);
  border-right: 1px solid var(--qkn-medium-gray2);

  .title-col { grid-area: title; }
  .product-col.simplifi { grid-area: firstCol; }
  .product-col.prem-del { grid-area: secondCol; }
  .product-col.business { grid-area: thirdCol; }

  .blank-title-col { grid-area: blankCol; }
  .prod-title.simplifi { grid-area: firstColTitle; }
  .prod-title.premier-del { grid-area: secondColTitle; }
  .prod-title.business { grid-area: thirdColTitle; }

  .title-col {
    min-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.14px;
    border: 1px solid var(--qkn-medium-gray2);

    @include bp(md) {
      font-size: 16px;
      justify-content: start;
      align-items: end;
      padding-left: 30px;
      padding-bottom: 20px;
      border: none;
    }
  }

  .product-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;

    .award-image {
      display: none;

      @include bp(md) {
        display: initial;
      }

      position: absolute;
      top: -20px;

      img {
        width: 211px;
        height: auto;
      }
    }

    .head-contain {
      &.deluxe {
        display: none;
      }
    }

    img {
      height: 54px;
      width: 54px;
      margin-bottom: 10px;
    }

    &.simplifi {

      border-right: 1px solid var(--bright-gray);

      @include bp(md) {
        border: none;
      }
      .cta {
        margin-top: 20px;

        @include bp(md) {
          margin-top: 0px;
        }
      }
    }

    .title-line {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include bp(md) {
        flex-direction: row;
      }

      &.has-tier {
        padding-bottom: 5px;
      }

      &:not(.has-tier) {
        .product-group {
          padding-bottom: 5px;
        }
      }

      .product-group {
        font-size: 17px;
        font-weight: 600;
        line-height: 1.4;
        letter-spacing: -0.085px;
      }

      .product-tier {
        border-radius: 3px;
        background: var(--qkn-red-background);
        padding: 4px;
        color: var(--qkn-red);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;

        @include bp(md) {
          margin-left: 4px;
        }
      }
    }

    .price-contain {
      max-width: 140px;
      padding-bottom: 40px;

      .price,
      .price-cents,
      .per {
        font-weight: 400;
      }

      @include bp(md) {
        max-width: 276px;
      }

      .pre-text {
        display: none;
      }
    }

    .cta {
      padding-bottom: 8px;
      a {
        text-decoration: underline;
      }
    }

    &.simplifi-purple-theme {
      @include bp(md) {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-top: 4px solid var(--qkn-blurple);
        border-left: 4px solid var(--qkn-blurple);
        border-right: 4px solid var(--qkn-blurple);
      }

      .cta a {
        color: var(--qkn-blurple);
        &:hover {
          background: var(--qkn-light-blurple);
        }
      }
    }

    &.classic-red-theme {
      .cta a {
        color:var(--qkn-red);
        &:hover {
          background: var(--qkn-light-red);
        }
      }
    }

    #compare-select {
      appearance: none;
      width: 143px;
      height: 36px;
      padding-left: 17px;
      border-radius: 4px;
      border: 1px solid var(--qkn-medium-gray2);
      background: url("/assets/images/chevron-down.svg") no-repeat right 15px center var(--white);
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: -0.14px;

      @include bp(md) {
        width: 220px;
      }

      &:after {
        position: absolute;
        content: "";
        top: 14px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: var(--white) transparent transparent transparent;
      }
    }
  }

  .prod-title,
  .blank-title-col {
    background-color: var(--qkn-medium-gray3);
  }

  .prod-title {
    min-height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-size: 13px;
    line-height: 1.4;
    letter-spacing: 0.13px;

    @include bp(md) {
      font-size: 16px;
    }

    &.simplifi-purple-theme {
      @include bp(md) {
        border-left: 4px solid var(--qkn-blurple);
        border-right: 4px solid var(--qkn-blurple);
      }
    }

    .title {
      font-weight: 600;
    }
  }
}

.compare-table-body {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  .row-group {
    cursor: pointer;
    &.active {
      background-color: var(--qkn-medium-gray3);
    }
  }

  .row-group,
  .row-subgroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 33px 60px;
    min-height: 93px;
    border-bottom: 1px solid var(--qkn-medium-gray2);
    border-right: 1px solid var(--qkn-medium-gray2);
    border-left: 1px solid var(--qkn-medium-gray2);



    @include bp(md) {
      grid-template-columns: 1fr 288px 288px;
    }

    grid-template-areas:
      "rowTitle rowTitle"
      "simpCol premierCol";

    &.show-deluxe {
      grid-template-areas:
        "rowTitle rowTitle"
        "simpCol deluxeCol";
    }

    &.show-business {
      grid-template-areas:
        "rowTitle"
        "busCol";
    }

    @include bp(md) {
      min-height: 56px;
      grid-template-areas: "rowTitle simpCol premierCol";
      grid-template-rows: auto;

      &.show-deluxe {
      grid-template-areas: "rowTitle simpCol deluxeCol";
      }
    }

    .row-title { grid-area: rowTitle; }
    .feature-data.simplifi { grid-area: simpCol; }
    .feature-data.premier { grid-area: premierCol; }
    .feature-data.deluxe { grid-area: deluxeCol; }
    .feature-data.business { grid-area: busCol; }

    &.active {

      .row-chevron {
        transform: rotate(180deg);
      }
    }

    .row-chevron {
      margin-right: 11px;
    }

    .row-title {

      line-height: 1.4;
      letter-spacing: -0.14px;
      padding-top: 12px;
      padding-left: 12px;

      display: flex;
      flex-direction: row;
      align-items: center;

      &.parent-title {
        font-weight: 600;
        font-size: 16px;
      }

      // &.subtitle {
      //   font-size: 14px;
      // }

      .subtitle-contain {
        display: flex;
        align-items: center;

        .tooltip-icon {
          cursor: pointer;
        }

        p {
          margin-right: 4px;
        }
      }

      .tooltip-icon:hover:has(> .tooltip)  {
        display: flex;
      }

      .tooltip {
        display: none;
        padding: 8px 12px;
        border-radius: 4px;
        background: var(--light-purple2);
        max-width: 250px;
        position: absolute;
        top: 40px;
        z-index: 2;

        p {
          color: var(--base-grey-100, #18181F);
          font-size: 14px;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.14px;
        }
      }

      &.active {
        background-color: var(--qkn-medium-gray3);
      }

      &.subtitle {
        font-size: 14px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
      }

      @include bp(md) {
        font-size: 16px;
        display: flex;
        padding-left: 23px;
        padding-top: 0px;
        align-items: center;
      }
    }

    .feature-data {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      @include bp(md) {
        // These side borders are weird, for more context:
        // https://stackoverflow.com/questions/28897089/z-index-on-borders
        &.simplifi::after,
        &.simplifi::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 106%;
          top: 0px;
        }

        &.simplifi::before {
          border-left: 4px solid var(--qkn-blurple);
          left: 0px;
        }
        &.simplifi::after {
          border-right: 4px solid var(--qkn-blurple);
          right: 0px;
        }
      }

      p {
        color: var(--qkn-light-gray);
        text-align: center;
        font-size: 13px;
        line-height: 1.4;
        letter-spacing: 0.13px;
        max-width: 90%;
        padding-top: 5px;

        @include bp(md) {
          font-size: 14px;
          max-width: 80%;
          padding-top: 0;
        }
      }
    }
  }


  .row-subgroup {
    display: none;
    $rowBorder: 3px solid var(--bright-gray);
    border-left: $rowBorder;
    border-right: $rowBorder;

    &.active {
      display: grid;
    }

    &.final-subrow {
      border-bottom: $rowBorder;
    }

    @include bp(md) {
      border-left: none;
      border-right: none;

      .feature-data {

        &.premier,
        &.deluxe {
          border-right: $rowBorder;
        }

        // &:last-of-type {
        //   border-bottom: $rowBorder;
        // }
      }

      .subtitle {
        border-right: $rowBorder;
        border-left: $rowBorder;

        @include bp(md) {
          border-right: none;
        }
      }
    }

    .feature-data {
      @include bp(md) {
        &.simplifi::before {
          left: -1px;
        }

        &.simplifi::after {
          right: 1px;
        }
      }
    }
  }
}

.compare-footer {
  border-bottom-left-radius: $border-radius-default;
  border-bottom-right-radius: $border-radius-default;
  border-bottom: 1px solid var(--qkn-medium-gray2);
  border-left: 1px solid var(--qkn-medium-gray2);
  border-right: 1px solid var(--qkn-medium-gray2);

  .blank-col {
    display: none;

    @include bp(md) {
      display: initial;
    }
  }

  @include bp(md) {
    .product-footer-col {
      &.simplifi-purple-theme {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: 4px solid var(--qkn-blurple);
        border-left: 4px solid var(--qkn-blurple);
        border-right: 4px solid var(--qkn-blurple);
      }
    }
  }

  .foot-price {
    padding-bottom: 15px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -0.14px;

    &.top {
      .pre-text,
      .full-price,
      .percentage,
      .billed {
        display: none;
      }
    }
  }

  .btm-cta {
    padding-bottom: 15px;

    button {
      @include bp(md) {
        width: 248px;
        height: 48px;
        font-size: 17px;
      }
    }
  }

  .bottom-price {
    padding-bottom: 40px;

    .pre-text,
    .full-price,
    .price,
    .price-cents,
    .per,
    .percentage {
      display: none;
    }

    .billed {
      font-size: 13px;
      line-height: 1.4;
      letter-spacing: 0.13px;
      color: var(--qkn-light-gray-opaque);
    }
  }
}

.compare-footnote {
  max-width: 546px;
  margin: 0 auto;
  padding-top: 5px;

  .note {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    font-size: 12px;
  }

  svg {
    height: 15px;
    width: 15px;
  }

  @include bp(md) {
    max-width: 944px;
  }
}