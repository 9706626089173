
.support-hero {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 21px;

  border-radius: 25px;
  background: var(--card-gray);

  .hero-col {
    flex-basis: 100%;
    @include bp(md) {
      flex-basis: 98%;
    }

    &.hero-right-image {
      img {
        display: block;
        margin: 0 auto;
        border-radius: 0 0 25px 25px;
        width: 100%;
      }
    }

    @include bp(mobile) {
      &.hero-right-image {
        img {
          width: 75%;
          max-width: 650px;
          border-radius: 25px 25px 0 0;
        }
      }
    }

    @include bp(lg) {
      flex-basis: 50%;
      &.hero-right-image {
        img {
          float: right;
          border-radius: 0 25px 25px 0;
          // width: unset;
        }
      }
    }

  }

  .care-search-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    @include bp(lg) {
      align-items: unset;
    }

    padding: 21px;
    @include bp(md) {
      padding: 34px;
    }

    &.search-row {
      display: flex;
      flex-direction: column;
      @include bp(sm-md) {
        flex-direction: row;
      }
      align-items: center;
      width: 100%;
    }

    .qkn-classic-header-label {
      @include bp(lg) {
        width: 450px;
      }

      .header-label-items {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 21px;

        h4 {
          font-size: 21px;
          margin-bottom: unset; 
        }

        img {
          margin-right: 13px;
        }
      }

      &.care-header-search-row {
        width: 100%; 
        @include bp(sm-md) {
          width: 50%; 
        }

        .header-label-items {
          margin-bottom: 21px;
          @include bp(sm-md) {
            margin-bottom: unset;
          }

          h4 {
            margin-bottom: unset; 
          }
        }
      }
    }

    .care-hero-heading {
      h1 {
        font-size: 34px;
        @include bp(md) {
          font-size: 48px;
          width: unset;
        }
      }
    }

    .search-form-items {
      width: 98%;
      @include bp(lg) {
        width: inherit;
      }

      .search-form-wrap {
        background: var(--white);
        border-radius: 13px;
        margin: 0 auto;

        @include bp(lg) {
          margin: 13px auto;
        }

        @include bp(sm-md) {
          border-radius: 60px;
          width: 88%;
        }

        @include bp(md) {
          width: 85%;
        }

        @include bp(navbar-breakpoint) {
          width: 75%;
        }

        @include bp(lg) {
          width: 100%;
        }

        &.search-page {
          width: 100%;
        }

        padding: 12px;

        .hero-search-form {
          display: block;
          position: relative;

          &::before {
            background: url(/assets/images/support/magnify.svg) no-repeat;
            background-size: auto;
            background-size: contain;
            content: "";
            display: block;
            height: 21px;
            width: 21px;
            position: absolute;
            top: 15px;
            left: 3px;
            z-index: 1;
          }

          @include bp(sm-md) {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
          }

          .search-input {
            position: relative;
            left: 25px;
            font-size: 16px;
            outline: none;

            @include bp(md) {
              font-size: 18px;
            }

            width: 90%;
            @include bp(mobile) {
              width: 95%;
            }

            height: 50px;
            border: none;
            margin-left: 8px;
          }

          .support-search-submit {
            position: relative;
            width: 100%;
            flex-basis: 175px;
            background: var(--qkn-red);
            color: var(--white);
            height: 50px;
            border-radius: 300px;
          }
        }
      }
    }
  }
}


