
.faq-accordian {
  background: var(--card-gray);
  margin-left: auto;
  margin-right: auto;
  max-width: 1460px; 
  width: 100%;

  .faq-accordian-padding {
    display: flex;
    padding: 64px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
  

    @include bp(md-lg) {
      flex-direction: row;
      align-items: flex-start;
      padding: 72px 132px 72px 132px;
      margin: auto;
      max-width: 100%;
      gap: 150px;
    }
  }

  .faq-accordian-title {
    max-width: 350px;
    text-align: center;

    @include bp(md-lg) {
      max-width: 440px;
    }

    & > * {
      color: var(--darkGray);
      text-align: center;
      font-weight: 600;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 0.9;
      letter-spacing: -1.6px;

      @include bp(md-lg) {
        margin: 0;
        font-size: 72px;
        line-height: 1;
        letter-spacing: -2.16px;
        text-align: left;
      }
    }
  }

  .faq-accordian-cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-width: 640px;

    @include bp(sm-md) {
      width: 640px;
    }

    @include bp(md-lg) {
      width: 539px;
    }

    .faq-accordian-card-text {
      display: none;

      &.active {
        display: flex;
      }
    }

    .faq-accordian-card-container {
      display: flex;
      width: 100%;
      padding: 28px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      border-radius: $border-radius-default;
      background: var(--white);

      @include bp(md) {

        padding: 24px;
        border-radius: 8px;
      }

      .faq-accordian-card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 10px;

        h4 {
          color: var(--darkGray);
          font-weight: 600;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: 1.4;
          letter-spacing: -0.085px;

          @include bp(md-lg) {
            color: var(--black);
            font-size: 19px;
            line-height: 1.35;
            letter-spacing: -0.095px;
          }
        }

        .faq-accordian-card-icon {
          width: 33px;
          height: 33px;
          flex-shrink: 0;
          cursor: pointer;

          .minus, .plus {
            display: none;

            &.active {
              display: flex;
            }
          }

          @include bp(md-lg) {
            width: 36px;
            height: 36px;
          }

          svg {
            position: absolute;
            margin-top: 0;
          }
        }
      }

      .faq-accordian-card-text {
        max-width: 456px;
        flex-wrap: wrap;

        p {
          color: var(--qkn-light-gray);
          font-size: 16px;
          font-weight: 400;
          line-height: 1.4;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0px;
          }
        }

        ul {
          list-style: disc;
          padding-left: 16px;

          li {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0px;
            }
          }
        }

        .faq-accordian-purple {
          color: var(--qkn-blurple);
        }
      }
    }
  }
}