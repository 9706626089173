$formGap: 42px;
$formGapMobile: 20px;

.form-component {
  scroll-margin-top: 80px;
  
  .marketing-preferences,
  .field-grp {
    display: flex;
    flex-direction: column;
  }

  .form-container.marketing-preferences-form {
    max-width: 426px;
    margin: 0 auto;

    .marketing-preferences {
      width: 100%;
      gap: $formGapMobile;
      padding: 0px 20px;
  
      @include bp(md) {
        gap: $formGap;
        padding: unset;
      }
  
      h4 {
        margin: 0px;
      }

      .error-msg {
        display: none;
        color: var(--error-red);
        padding: 10px 0px;

        &.show {
          display: block;
        }
      }
      
      .checkboxes {
        input[type='checkbox'] {
          width: 20px;
          height: 20px;
          border-radius: 2px;
          border: 1px solid var(--qkn-light-gray);
          accent-color: var(--qkn-blurple);

          &.has-error {
            border: 2px solid var(--error-red);
            accent-color: var(--error-red);
          }
        }

        legend {
          padding-bottom: 5px;
        }

        .check-grp {
          &:not(:last-child) {
            padding-bottom: 15px;
          }

          &.checks {
            display: flex;
            align-items: center;
          }

          label {
            padding-left: 5px;
          }
        }
      }


      .mp-fields {
        display: none;
        &.show {
          display: block;
        }

        .field-grp {
          display: none;
          &.show {
            display: flex;
          }
          &.has-error {
            color: var(--error-red);

            input,
            select {
              border: 1px solid var(--error-red);
            }

            label {
              color: var(--error-red);
            }
          }
        }

        .field-grp:not(:first-child) {
          padding-top: $formGapMobile;

          @include bp(md) {
            padding-top: $formGap;
          }
        }

        label {
          padding-bottom: 10px;

          // &.has-error {
          //   color: var(--error-red);
          // }
        }


        select {
          color: var(--qkn-light-gray);
          opacity: .6;
        }

        input,
        select {
          padding: 12px 16px;
          border: 1px solid var(--qkn-border-gray1);
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
          border-radius: 4px;



          &::placeholder {
            color: var(--qkn-light-gray);
            opacity: 0.6;
          }
          
          &:focus,
          &:focus-visible {
            border: 2px solid var(--qkn-blurple);
            outline: none;
          }
        }
      }

      .mp-submit {
        button {
          width: 100%;
        }
      }
    }

    .result-message {
      display: none;

      .success,
      .error {
        display: none;
      }
    }
  }

  .result-message {
    max-width: 426px;
    margin: 0 auto;
    background-color: var(--card-gray);
    padding: 50px 38px;
    border-radius: 24px;
    
    .mp-back {
      padding-top: $formGapMobile;
      @include bp(md) {
        padding-top: $formGap;
      }
    }
  }
}