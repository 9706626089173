 //style for banner only
 .countdown-timer {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0px;
   max-width: 559px;
   @include bp(md-lg, max) {
    justify-content: left;
  }

   .time-container {
     display: none; //"none" to avoid the timer pop-up before JS loads, managed by JS in Countdown Timer
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     max-width: 164px;
     min-width: 105px;
     margin-right: 24px;

     @include bp(md-lg, max) {
       max-width: 49px;
       margin-right: 0;
     }
   }

   .time-unit {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding: 0;
     border-radius: 4px;
     margin: 2px;
     font-style: normal;
     font-weight: 600;
     line-height: 120%;
     width: 49%;
   }

   .value {
     display: inline-block;
     align-items: center;
     font-weight: 600;
     font-size: 24px;
     line-height: 120%;
     font-style: normal;
     position: relative;
     border-radius: 4px 4px 0px 0px;
     width: 100%;
     text-align: center;
     padding-left: 2px;
     padding-right: 2px;
   }

   .label {
     display: flex;
     justify-content: center;
     margin-top: 2px;
     font-size: 10px;
     font-weight: 600;
     font-style: normal;
     line-height: 150%;
     position: relative;
     border-radius: 0px 0px 4px 4px;
     width: 100%;
   }

   .timer-text {
     font-size: 16px;
     font-weight: 600;
     line-height: 140%;

     @include bp(md-lg, max) {
       font-size: 14px;
       margin-left: 18px;
     }
   }

   .highlight-text {
     font-size: 16px;
     font-weight: 600;
     line-height: 140%;

     @include bp(md-lg, max) {
       font-size: 14px;
       margin-left: 18px;
     }
   }
 }