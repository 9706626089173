
.support-featured-videos-section {
  padding: 21px; 
  .support-section-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; 

    @include bp(sm-md) {
      align-items: stretch; 
    }

    .support-tile {
      flex-basis: 98%;
      margin-bottom: 34px;
  
      @include bp(sm-md) {
        flex-basis: 47%; 
      }

      @include bp(md) {
        flex-basis: 30%; 
      }

      @include bp(lg) {
        flex-basis: 32%; 
      }

      .featured-videos-tile {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center; 

        flex-direction: column;
        height: 100%;
        background: var(--card-gray);
        padding: 8px;
        margin: 8px;
        border: 1px solid #eee;
        border-radius: 30px;

        h4 {
          font-size: 18px;
        }

        .featured-videos-item {
          padding: 13px; 
        }

        .featured-video-cta {
          img {
            @include bp(md) {
              width: 268px;
            }
            @include bp(navbar-breakpoint) {
              width: unset; 
            }
          }
        }

        .featured-videos-os-icon {
          .icon-wrapper {
            .os-icon-wrap {
              border-radius: 50px;
              width: 41px;
              height: 41px;
              margin: 5px auto;
              padding: 8px;
              background: #fefefe;

              .os-icon {
                padding: 2px;
                width: 21px;
                height: 21px; 
              }
            }
          }  
        }
      }

    }
  }
}

