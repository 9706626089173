.support-footer {
  margin: 21px; 
  padding: 70px; 
  background: var(--card-gray); 
  border-radius: 25px; 

  .contact-support-header {
    text-align: center;
  }

  .contact-support-methods {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; 

    margin-top: 70px; 

    .contact-tile {
      flex-basis: 97%;
      margin-bottom: 21px;  

      @include bp(sm-md) {
        flex-basis: 45%; 
      }

      @include bp(md) {
        flex-basis: 23%; 
        margin-bottom: unset; 
      }

      display: flex;
      flex-direction: column;
      padding: 13px; 
      justify-content: flex-start;
  
      h3 {
        font-size: 28px; 
        flex: unset; 
      }

      .header-icon-wrapper {
        background: #e8e7fc;
        border-radius: 9px;
        margin-bottom: 21px; 
      }

      .support-pane-wait-times {
        margin-top: 15px; 

        &.hours-closed {
          margin-top: unset; 
        }
      }

      .cta-destination {
        margin-top: 15px;

        .chat-closed-header {
          font-size: 1em;
          margin-bottom: 15px;  
        }

        a {
          color: var(--qkn-blurple); 
          &:hover {
            background: inherit; 
          } 
          cursor: pointer; 
        } 
      }

      &.contact-tile {
        .support-pane-icon {
          background: var(--light-purple2-40p); 
          border-radius: 9px;
          margin-bottom: 21px;
          width: fit-content;

          img {
            position: relative;
            left: -3px;
            top: 1px;
            margin: 8px;
            width: 20px;
            height: 20px;
          }
        }
      }

      &.community-support-tile {
        .support-pane-icon {
          background-image: url(/assets/images/support/team-line-tile.svg);
          background-repeat: no-repeat;
          width: 58px;
          height: 37px;
          background-position: 50%;
          background-size: 34%;
        }
      }

      &.chatgpt-tile {
        .chatgpt-pane-icon {
          background-image: url(/assets/images/support/question-answer-line-gpt.svg);
          background-repeat: no-repeat;
          width: 58px;
          height: 37px;
          background-position: 50%;
          background-size: 34%;
        }
      }

      &.chat-with-us-tile {
        .support-pane-icon {
          background-image: url(/assets/images/support/question-answer-line-gpt.svg);
          background-repeat: no-repeat;
          width: 58px;
          height: 37px;
          background-position: 50%;
          background-size: 34%;
        }
      }

      &.call-us-tile {
        .support-pane-icon {
          background-image: url(/assets/images/support/customer-service.svg);
          background-repeat: no-repeat;
          width: 58px;
          height: 37px;
          background-position: 50%;
          background-size: 34%;
        }
      }

    }
  }
}

.contact-modal-wrapper {
  display: none; 
}

