.duplex-container {
  width: 100%;
  $margin-horizontal: 20px;
  padding: 40px 0 40px 0;

  @include bp(md, max) {
    padding: 64px 0 32px;
  }

  .product-icons {
    display: flex;
    flex-direction: row;
    align-items: normal;
    padding-top: 24px;
    line-height: 140%;

    .icon-container {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }

    p {
      font-size: 17px;
      margin-right: 19px;
      margin-left: 3px;

      @include bp(sm-md) {
        font-size: 17px;
      }
    }
  }

  .price-section {
    margin: 24px 0;
    text-align: center;
    line-height: 1.5;

    &.inline {
      justify-content: start;
    }
  }


  .countdown-timer {
    max-width: 483px;
    min-width: 314px;
    padding: 12px 15px 11px 12px;
    border-radius: 16px;
    border: 1px solid var(--qkn-mid-blue-background);
    margin: 20px 0px;

    .time-container {
      min-width: 106px;
      margin-right: 44px;

      @include bp(md-lg, max) {
        margin-right: 18px;
      }
    }

    .time-unit {
      width: 53%;
      height: 74px;
      flex-shrink: 0;

      @include bp(md-lg, max) {
        width: 49px;
        height: 48px;
      }
    }

    .value {
      font-size: 30px;
      line-height: 120%;
      border-radius: 10px 10px 0px 0px;
      letter-spacing: -0, 9x;

      @include bp(md-lg, max) {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.72px;
      }
    }

    .label {
      font-size: 12px;
      border-radius: 0px 0px 10px 10px;
      width: 100%;

      @include bp(md-lg, max) {
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }

    .highlight-text,
    .timer-text {
      font-size: 23px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.23px;

      @include bp(md-lg, max) {
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
        width: 176px;
        margin-left: 0;

      }
    }
  }


  .duplex-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding-top: 20px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 20px;
    width: 100%;

    @include bp(md, max) {
      padding: 0px 20px;
    }

    &.without-image {
      .left-side {
        margin-bottom: 0px;
      }

      .left-side,
      .right-side {
        .content {
          padding-top: 0;
          padding-bottom: 32px;

          @include bp(md, max) {
            justify-content: center;
            padding-right: 0px;
          }

          p {
            font-size: 23px;
            line-height: 34px;
            color: var(--qkn-light-gray);

            @include bp(md, max) {
              font-size: 19px;
              line-height: 25px;
              text-align: left;
            }

            @include bp(md) {
              text-align: left;
            }
          }
        }

        @include bp(md, max) {
          text-align: center;
        }
      }
    }

    .left-side,
    .right-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(50% - ($margin-horizontal / 2));

      @include bp(md, max) {
        width: 100%;
        padding: 0;
      }

      .pre-title {
        font-size: 21px;
        margin-bottom: 16px;
      }

      .content {
        padding: 24px 30px 24px 0;
        line-height: 140%;
        color: var(--qkn-light-gray, #494949);
        font-size: 19px;

        @include bp(md, min) {
          padding: 24px 250px 30px 0;
        }

        @include bp(sm, min) {
          padding: 24px 30px 30px 0;
        }

        @include bp(md) {
          padding-top: 20px;
        }
      }

      .h1-wrapper,
      &>p {
        font-size: 32px;

        @include bp(md) {
          font-size: 48px;
        }
      }

      img,
      video {
        width: 100%;
        max-width: 650px;
        height: auto;
        margin: auto;
      }

      .awards {
        width: fit-content;
        display: block;
        margin: 0;
        margin-top: 24px;
      }

      .headline-img {
        width: unset;
        max-width: 100%;
      }

      button {
        align-self: flex-start;
      }
    }

    .left-side {
      margin-right: calc($margin-horizontal / 2);
    }

    .right-side {
      margin-left: calc($margin-horizontal / 2);
      text-align: left;
    }

    @include bp(md, max) {
      .left-side {
        margin-bottom: 48px;
      }
    }
  }

  .right-side {
    text-align: center;
  }

  .left-image {
    @include bp(md, max) {
      .left-side {
        order: 1;
        margin-top: 48px;
        margin-bottom: 0px;
        text-align: center;
      }

      .right-side {
        text-align: left;
      }
    }
  }
}