.in-product-container {
  // flex-direction: column;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 540px;

  .inproduct-img {
    max-width: 100%;
    height: auto;
  }

  .content {
    text-align: center;
    flex-direction: column;
  }

  .inproduct-btn {
    padding-top: 20px;
  }
}