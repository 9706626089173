.embed-video {
  position: relative;
  max-width: 85%;
  margin: auto;

  .btn-grp {
    display: none;
    &.mobile {
      display: initial;
    }

    @include bp(mobile) {
      display: initial;
      &.mobile {
        display: none;
      }
    }
  }

  .btn-grp-mobile {
    @include bp(mobile) {
      display: none;
    }
  }

  .embed-link {
    position: relative;
    max-width: 100%;
    height: auto;
    cursor: pointer;
  }

  .btn-grp {
    cursor: pointer;
  }

  svg {
    background: none;
    cursor: pointer;
  }

  .play-btn {
    &:hover {
      circle {
        fill: var(--qkn-blurple2);
      }
    }
  }

  .btn-grp {
    .corner-icons {
      position: absolute;
      bottom: 2px;
      right: 0;
    
      &.left-curve {
        right: 53px;
      }

      &.top-curve {
        bottom: 82px;
      }
    }

    &.mobile {
      .corner-icons { 
        &.left-curve {
          right: 57px;
        }
      
        &.top-curve {
          bottom: 60px;
        }
      }
    }
  }

}