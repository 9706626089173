.tri-box-hero {
  $outerPaddingMd: 116px;
  $innerPaddingMd: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  border-radius: $border-radius-default;
  // height: 623px;
  // max-height: 100%;
  max-width: calc($max-width - 63px);
  margin: 0px auto 24px auto;
  padding: unset;

  @include bp(md) {
    // max-height: 623px;
    overflow: hidden;
    margin: 0px auto 40px auto;
  }

  @include bp(lg) {
    width: 100%;
    margin: 0px auto 40px auto;
  }

  .top {
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    @include bp(md) {
      margin-top: 64px;
      flex-direction: row;
    }
  }

  .left {
    // margin-bottom: 9px;
    display: flex;
    flex-direction: column;

    @include bp(md) {
      width: 50%;
      padding-left: $outerPaddingMd;
      padding-right: $innerPaddingMd;
      text-align: left;
    }

    .title {
      font-size: 26px;
      font-weight: 400;
      text-align: center;
      color: var(--qkn-medium-gray);
      display: flex;
      flex-direction: column;
      padding: 0px 24px;

      @include bp(md) {
        padding: unset;
        padding-right: 16px;
      }

      @include bp(md) {
        text-align: left;
      }

      p {
        line-height: 95%;
        max-height: 136px;

        // Allows rich-text empty paragraphs to consume vertical space
        // Requested by Marketing 10/30/2024
        &:after {
          content: '\00a0';
        }
      }
    }

    .award-logo {
      gap: 12px;
      margin-top: 40px;
      // height: 49px;

      @include bp(md) {
        margin-top: 14px;
      }
      @include bp(md, max) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 5px;
        margin-bottom: 0;
        width: unset;
      }
      img {
        height: 51px;
        width: auto;

        @include bp(md) {
          height: 68px;
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    width: 70%;

    @include bp(md) {
      color: var(--darkGray, #18181F);
      text-align: left;
      margin-left: unset;
      margin-right: unset;
      padding-right: $outerPaddingMd;
      padding-left: $innerPaddingMd;
      width: 50%;
    }

    .sub-heading {
      text-align: center;
      font-size: 15px;
      line-height: 1.5;
      padding: 0;
      margin: auto;
      color: var(--qkn-medium-gray);

      @include bp(md) {
        margin: 0;
        font-size: 23px;
        text-align: left;
      }
    }

    .ctas {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 28px;
      padding-bottom: 12px;
      gap: 10px;

      @include bp(sm) {
        flex-direction: row;
      }

      @include bp(md, max) {
        button {
          font-size: 17px;

          a {
            padding: 12px 20px;
          }
          &.media-btn {
            padding: 12px 16px;
          }
        }
      }

      @include bp(md) {
        flex-direction: row;
        justify-content: left;
        margin: 0;
        padding-bottom: 19px;

        &.align-right {
          justify-content: right;
        }
      }
    }

    @include bp(md, max) {
      .price-section {
        text-align: center;
        line-height: 1.5;
      }
    }
  }

    .bottom {
      display: flex;
      justify-content: center;
      max-height: 315px;
  
      .lottie-animation {
        width: 100%;
        height: auto;
      }
  
      .image {
        padding-top: 25px;
        margin: 0px 20px;
  
        @include bp(sm-md) {
          margin: unset;
          padding-top: 43px;
        }
  
        img {
          width: 95vw;
          max-width: 100%;
          height: 100%;
          object-fit: cover;
  
          @include bp(sm-md) {
            max-width: 680px;
          }
        }
      }
    }
  
    &.headline-cta-only {
      .top {
        align-items: flex-end;
      }
  
      .left {
        .title {
          justify-content: flex-end;
          margin-top: auto;
  
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            margin: 0;
            line-height: normal;
          }
        }
      }
  
      .right {
        .ctas {
          margin-top: auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 10px;
  
          @include bp(md) {
            justify-content: left;
            padding-bottom: 45px;
  
            &.align-right {
              justify-content: flex-end;
              padding-bottom: 45px;
            }
          }
        }
      }
    }
    }