// shared card container styling

// Hacky override for selector card to squish padding between them and the selector options for QMNT-8570
[data-type="product-cards-container"]:has(div.selector-cards) {
  padding-top: 0px;
}

#selector-cards {
  padding-top: 40px;
}

// #product-cards
#compact-cards,
#selector-cards,
#full-page-cards {
  display: flex;
  align-items: center;
  flex-direction: column;

  .title-wrap {
    max-width: 650px;
    text-align: center;
    padding-top: 64px;
    margin-bottom: 48px;
    line-height: .95;
    letter-spacing: 0px;

    @include bp(sm-md) {
      max-width: 900px;
      letter-spacing: 0px;
    }
  }

  .title-toggle {
    .toggle-container {
      width: 262px;
    }
  }

  //has one more property then other timers
  .countdown-timer {
    justify-self: center;
    grid-area: subline;
    max-width: 483px;
    min-width: 314px;
    height: 97px;
    padding: 12px 15px 11px 12px;

    @include bp(md-lg, max) {
      border-radius: 16px;
      border: 1px solid var(--qkn-mid-blue-background);
    }

    .time-container {
      margin-right: 44px;

      @include bp(md-lg, max) {
        margin-right: 18px;
      }
    }

    .time-unit {
      width: 53%;
      height: 74px;
      flex-shrink: 0;

      @include bp(md-lg, max) {
        width: 49px;
        height: 48px;
      }
    }

    .value {
      font-weight: 600;
      font-size: 30px;
      border-radius: 10px 10px 0px 0px;
      text-align: center;
      letter-spacing: -0, 9x;

      @include bp(md-lg, max) {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.72px;
      }
    }

    .label {
      font-size: 12px;
      border-radius: 0px 0px 10px 10px;
      width: 100%;

      @include bp(md-lg, max) {
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }

    .highlight-text,
    .timer-text {
      font-size: 23px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.23px;
      width: 268px;
      display: flex;

      @include bp(md-lg, max) {
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
        width: 190px;
        margin-left: 0;
      }
    }
  }


  .sl-contain {
    display: flex;
    flex-direction: row;
    align-items: center;
    // margin: 25px 72px 31px;
    // padding: 30px 70px;

    .flair {
      $flair-size: 80px;
      height: $flair-size;
      width: $flair-size;
    }

    &.flair-right {
      flex-direction: row-reverse;
    }

    .subline {
      margin: 0px 16px;

      h3 {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: -0.095px;

        @include bp(sm-md) {
          font-size: 17px;
        }
      }
    }
  }

  .cards {
    $padding-top-product-card-sm: 0px;
    $padding-top-product-card-sm-md: 25px;
    $margin-top-award-topper: -23px;
    $margin-top-award-topper-sm: calc($margin-top-award-topper - $padding-top-product-card-sm);
    $margin-top-award-topper-sm-md: calc($margin-top-award-topper - $padding-top-product-card-sm-md);

    margin-top: calc($margin-top-award-topper-sm * -1);

    .hidden-card {
      display: none;
    }

    .shown-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .product-card {
      border-radius: $border-radius-default;
      background-color: var(--white);
      padding-top: $padding-top-product-card-sm;

      @include bp(sm-md) {
        padding-top: $padding-top-product-card-sm-md;
      }

      .flex-wrap {
        display: flex;
        flex-direction: column;

        @include bp(sm-md) {
          .flex-left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

          }

          .flex-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .card-swapper {
              height: 100%;
            }
          }
        }
      }

      &.hidden-group {
        display: none;
      }

      &.shown-group {
        display: block;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .award-margin {
        margin-top: 19px;
      }

      .award-topper {
        display: flex;
        justify-content: center;
        margin-top: $margin-top-award-topper-sm;

        .award-desktop {
          display: none;
        }

        img {
          max-height: 44px;
          width: auto;
        }

        @include bp(sm-md) {
          margin-top: $margin-top-award-topper-sm-md;

          .award-desktop {
            display: flex;
          }

          .award-mob {
            display: none
          }
        }
      }

      .product-header {
        @include bp(342px, max) {
          flex-direction: column;
        }

        display: flex;
        flex-direction: row;
        padding-left: 25px;
        padding-top: 25px;
        max-width: 96%;
        align-items: center;

        @include bp(sm-md) {
          padding-top: 35px;
        }

        .product-logo {
          height: 52px;
          width: 52px;
          margin-right: 25px;

          @include bp(sm-md) {
            height: 72px;
            width: 72px;
            margin-right: 20px;
          }
        }

        .product-title {
          color: var(--eerie-black, #18181F);
          font-size: 24px;
          line-height: 1.2;
          letter-spacing: -0.24px;
          padding-bottom: 2px;
          text-align: left;

          @include bp(sm-md) {
            font-size: 26px;
          }
        }

        .product-icons {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            height: 20px;
            width: 20px;
            margin-right: 5px;
          }

          p {
            font-size: 14px;
            margin-right: 19px;
            margin-left: 3px;

            @include bp(sm-md) {
              font-size: 17px;
            }
          }
        }
      }

      .price-contain {
        display: flex;
        text-align: left;
      }

      .price-break {
        padding: 0px 28px;

        @include bp(sm-md) {
          display: none;
        }

        &:after {
          content: "";
          display: block;
          border-bottom: 1px solid var(--qkn-medium-gray2);
          height: 1px;
          padding: 0px 28px;
        }
      }

      .toggle-contain {
        padding: 20px 28px 0px;
        // max-width: auto;
        text-align: left;

        @include bp(sm-md) {
          max-width: 326px;
        }

        p {
          margin-bottom: 8px;
        }

        .arrow-icon {
          display: none;

          svg {

            &.flipped {
              transform: scale(-1, 1);
              transform-origin: center;
              position: absolute;
              right: -97px;
            }

            @include bp(sm-md) {
              height: 53px;
              width: 42px;
            }
          }

          @include bp(sm-md) {
            display: block;
            position: absolute;
            right: 140%;
            top: 10%;
          }
        }
      }

      .toggle-container {
        border: 1px solid var(--qkn-border-gray, #ECEDF1);
      }

      &.simplifi-purple-theme {
        .toggle-container {
          .switch {
            background: var(--qkn-blurple);
          }
        }
      }

      &.classic-red-theme {
        .toggle-container {

          .switch {
            border: 1px solid var(--qkn-red, #EB0130);
            background: var(--qkn-red-background);

            div {
              color: var(--black);
            }
          }
        }
      }

      .product-features {
        display: flex;
        flex-direction: column;
        padding: 25px 28px;
        text-align: left;

        @include bp(sm-md) {
          padding-top: 35px;

        }

        .faux-link {
          display: flex;
          align-items: center;
        }

        li {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding-bottom: 10px;
          color: var(--qkn-light-gray, #494949);
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: -0.14px;

          @include bp(sm-md) {
            font-size: 17px;
            line-height: 1.4;
            // padding-bottom: 16px;
          }
        }

        .feature-icon img {
          height: 19px;
          width: 19px;
          margin-right: 10px;

          @include bp(sm-md) {
            height: 20px;
            width: 20px;
            margin-right: 15px;
          }
        }
      }

      .product-ctas {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0px 28px 28px;

        @include bp(sm-md) {
          flex-direction: row;

          .cta-link {
            margin-left: 12.5%;
          }
        }

        a {
          font-size: 17px;
          line-height: 1.4;
        }

        .cta-btn {
          width: 100%;
          margin-bottom: 16px;

          @include bp(sm-md) {
            width: 50%;
            margin-bottom: 0;
          }

          a {
            letter-spacing: -0.085px;
          }
        }
      }
    }
  }

  .product-cards {
    .cta {
      display: flex;
      justify-content: center;
      padding-top: 15px;
    }
  }
}




.toggle-wrap {
  position: relative;

  .toggle-container {
    padding: 0px 3px;
    background-color: var(--white);
    border: 1px solid var(--qkn-blurple, #471CFF);
    border-radius: 7px;
    width: auto;

    .switches-container {
      display: flex;
      position: relative;
      line-height: 3;

      input:nth-of-type(1):checked~.switch-wrapper {
        transform: translateX(0%);
      }

      input:nth-of-type(2):checked~.switch-wrapper {
        transform: translateX(100%);
      }

      input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
        opacity: 1;
      }

      input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
        opacity: 1;
      }

      input {
        visibility: hidden;
        position: absolute;
        top: 0;
      }

      label {
        width: 50%;
        text-align: center;
        cursor: pointer;
        color: var(--black);
      }

      .switch-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
        padding: 7px 3px;
        // z-index: 3;
        transition: transform .5s cubic-bezier(.77, 0, .175, 1);

        .switch {
          border-radius: 3px;
          background: var(--qkn-blurple);
          height: 100%;

          div {
            width: 100%;
            text-align: center;
            opacity: 0;
            display: block;
            color: var(--white);
            transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
            will-change: opacity;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  .arrow-icon {
    display: block;
    position: absolute;
    right: -15%;
    top: 30%;

    @include bp(sm-md) {
      right: -35%;
      top: 0%;
    }

    svg {
      @include bp(sm-md) {
        height: 58px;
        width: 58px;
      }
    }

  }

  &.wlh {
    .toggle-container .switches-container .switch-wrapper {
      width: 33%;
    }

    .toggle-container .switches-container input:nth-of-type(2):checked~.switch-wrapper {
      transform: translateX(100%);
    }
    .toggle-container .switches-container input:nth-of-type(3):checked~.switch-wrapper {
      transform: translateX(200%);
    }

    input:nth-of-type(3):checked~.switch-wrapper .switch div:nth-of-type(3) {
      opacity: 1;
    }
  }
}

// if the title toggle is wrapped as it's own section
.toggle-section {
  display: flex;
  justify-content: center;

  .title-toggle {
    .toggle-container {
      width: 262px;
    }

    &.wlh {
      .toggle-container {
        width: 400px;
      }
    }
  }
}

// product selector card css for hiding showing based on selection winners
#selector-cards #regular-cards,
.selector-single-cards,
.selector-single-cards .product-card {
  display: none;

  &.active {
    display: block;
  }
}