.switch-hero-banner {
  $spacingStandard: 24px;
  width: 100%;

  .content {
    max-width: $max-width;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: $border-radius-default;
    padding: 64px 0;

    @include bp(md, max) {
      flex-direction: column;
      align-items: center;
      padding: 32px $spacingStandard;
    }

    .countdown-timer {
      max-width: 483px;
      min-width: 314px;
      padding: 12px 15px 11px 12px;
      border-radius: 16px;
      margin: 20px 0px;

      @include bp(md-lg, max) {
        width: 323px;
        border: 1px solid var(--qkn-mid-blue-background);
        padding: 12px 12px 12px 12px;
      }

      .time-container {
        margin-right: 44px;

        @include bp(md-lg, max) {
          margin-right: 0px;
        }
      }

      .time-unit {
        width: 55%;
        height: 74px;
        flex-shrink: 0;

        @include bp(md-lg, max) {
          width: 49px;
          height: 48px;
        }
      }

      .value {
        font-size: 30px;
        border-radius: 10px 10px 0px 0px;
        letter-spacing: -0, 9x;

        @include bp(md-lg, max) {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          letter-spacing: -0.72px;
        }
      }

      .label {
        font-size: 12px;
        border-radius: 0px 0px 10px 10px;

        @include bp(md-lg, max) {
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
      }

      .highlight-text,
      .timer-text {
        font-size: 23px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -0.23px;

        @include bp(md-lg, max) {
          font-size: 16px;
          font-weight: 600;
          line-height: 140%;
        }
      }
    }

    .banner-text,
    .banner-text .rich-text {
      align-items: flex-start;
      text-align: left;
      
      @include bp(md, max) {
        align-items: center;
        text-align: center;
      }
    }

    .banner-text {
      padding-left: 100px;
      padding-right: 70px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 90%;

      @include bp(md-lg, max) {
        padding-right: 0;
        padding-bottom: 0px;
      }

      @include bp(md, max) {
        padding-right: 0;
        padding-left: 0;
      }

      .pre-title {
        font-size: 21px;
        margin-bottom: 16px;
      }

      .headline-img {
        max-width: 100%;
        height: auto;
      }

      .subhead {
        padding-top: $spacingStandard;
        padding-right: 80px;
        font-size: 22px;
        line-height: 140%;
        color: var(--qkn-medium-gray);

        @include bp(md-lg, max) {
          font-size: 18px;
          padding-right: 0;
        }
      }

      .cta-section {
        margin-top: $spacingStandard;
        row-gap: $spacingStandard;
        column-gap: $spacingStandard;
        display: flex;
        align-items: center;
        flex-flow: wrap;
        justify-content: center;
      }

      .sub-cta-section {
        padding-top: $spacingStandard;

        .second-cta-subtext {
          display: none;
        }
      }

      .credentialsAwards {
        margin: 0;
        max-width: 100%;
        height: auto;

        &.mobile {
          margin: $spacingStandard auto 0;
          display: none;
        }

        @include bp(md, max) {
          &.mobile {
            display: block;
          }

          &.desktop {
            display: none;
          }
        }
      }
    }

    .banner-images {
      margin: auto;
      width: 100%;
      text-align: center;

      @include bp(md) {
        max-width: 50%;
      }

      .first-image,
      .second-image {
        display: block;

        @include bp(md) {
          padding-right: 48px;
        }

        img,
        video {
          max-width: 711px;
          width: 100%;
          height: auto;
          object-fit: contain;

          @include bp(md) {
            width: 100%;
          }
        }
      }

      .second-image {
        display: none;
      }
    }
  }
}