body.sidebar-layout #main > ul {
    padding: 12px;

    @include bp(md) {
        display: flex;
        gap: 145px;
        flex-direction: row;
    }

    &>li {
        &:first-child {
            @include bp(md, max) {
                position: sticky;
                top: 4em;
                z-index: 1;
            }

            @include bp(md) {
                min-width: 330px;
                margin-left: 91px;
            }
        }
    }

    section.table-of-contents {
        position: sticky;
        top: 7em;

        &>dl {
            max-height: 50vh;
            overflow-y: auto;
            background-color: var(--qkn-light-blue);
            border-radius: #{$border-radius-default * 0.5};
            padding: 19px 40px;

            @include bp(md) {
                max-height: 80vh;
            }

            /* This section will affect all levels of nesting */
            dt {
                display: flex;
                &>a {
                    display: block;
                    flex-grow: 1;
                    line-height: 150%;
                    padding: 12px 0;
                    cursor: pointer;
                    color: var(--darkGray);

                    &:hover,
                    &:active,
                    &:visited {
                        color: var(--darkGray);
                    }
                }

                /* Accordion Control */
                span {
                    display: block;
                    cursor: ns-resize;
                    background: url(/assets/images/chevron-14x7-down.svg) no-repeat center;
                    width: 15px;
                    height: 15px;
                    align-self: center;
                    text-indent: -900vw;
                }
                &.open {
                    span {
                        background-image: url(/assets/images/chevron-14x7-up.svg);
                    }

                    &+dd {
                        height: auto;
                    }
                }
            }
            dd {
                height: 0;
                overflow: hidden;
            }

            &>dt {
                /* Section first level */
                span {
                    font-size: 14px;
                }
            }
            &>dd>dl>dt {
                /* Section second level */
                &:not(:first-of-type) {
                    border-top: 1px solid var(--qkn-border-gray);
                }
                font-size: 17px;
                font-weight: 600;
                color: var(--darkGray);
            }


            /* This section applies only to the second level */
            &>dd>dl {
                padding: 40px 0 21px 0;

                &>dd>dl>dt[data-toc-visible="true"] a,
                &>dt[data-toc-visible="true"] a {
                    color: var(--qkn-blurple);
                }
                &>dt {
                    font-weight: bold;

                    /* Accordion Control */
                    span {
                        background-image: url(/assets/images/plus-icon.svg);
                        display: none;
                    }
                    &.open span {
                        background-image: url(/assets/images/minus-icon.svg);
                    }
                    &[data-toc-relation="mother"] span {
                        display: block;
                    }
                }
            }

            // Third level
            &>dd>dl>dd>dl {
                &>dt>span {
                    /* Accordion Control */
                    display: none;
                }
            }

            @include bp(md) {
                /*
                   Larger than mobile view, expand the first
                   level of TOC and hide the expander prompt
                */
                &>dt {
                    display: none;
                }
                &>dd {
                    height: auto;

                    &>dl {
                        padding-top: 21px;
                    }
                }
            }
        }
    }
}

body.sidebar-layout[data-toc-scroll-cue="active"] #main > ul > li:first-child h1.title {
    display: none;
}