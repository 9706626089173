// Need to clean these up
.component-cta {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-top: 70px;
  padding-bottom: 70px;
  width: 100%;

  .cta-content {
    width: 800px;
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;

    h2 {
      font-family: "Poppins Semi Bold", sans-serif;
      font-size: 42px;
      line-height: 52px;
      color: #22324F;
    }

    button {
      align-self: center;
      margin-top: 40px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-bottom: auto;
    }
  }
}

// Buy now button
.buy-now-red {
  padding: 10px 30px;
  background-color: rgb(247, 72, 82);
  color: white;
  border-radius: 40px;
  border-width: 0;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  margin-right: auto;
  margin-top: 0px;
  font-size: 16px;

  a {
    color: white;
  }
}

/*
CTA buttonand link color themeing
The CTA component can output 2 types of links: buttons wrapped in a's (.cta-btn) and just an a (.cta-link).
Any new colors added in contentful dropdown in the CTA should be added in here, not inside of
component css so both can be colored properly.
Current dropdown list:
.primary-blue
.primary-red
.secondary-red
.secondary-blue
.primary-blue-invert
.primary-eerie-black-invert
.link-primary-red
.link-primary-blue
.eerie-black
*/

.cta-btn {
  &.primary-blue {
    a {
      color: var(--white);
    }
  
    background-color: var(--qkn-blurple);
    border: 1px solid transparent;
  
    &:hover {
      a {
        color: var(--qkn-blurple);
      }
  
      background-color: var(--white);
      border-color: var(--qkn-medium-gray2);
    }
  }

  &.secondary-blue {
    background-color: var(--white);
    border: 1px solid var(--qkn-blurple);

    &:hover {
      background-color: var(--qkn-blurple);
      // border: 1px solid var(--qkn-blurple);
    }

    a {
      color: var(--qkn-blurple);

      &:hover {
        color: var(--white);
      }
    }
  }
  
  &.primary-red {
    a {
      color: var(--white);
    }

    background-color: var(--qkn-red);
    border: 1px solid transparent;

    &:hover {
      a {
        color: var(--qkn-red);
      }

      background-color: var(--white);
      border-color: var(--qkn-medium-gray2);
    }
  }
  
  &.secondary-red {
    background-color: var(--white);
    border: 1px solid var(--qkn-red);

    &:hover {
      background-color: var(--qkn-red);
    }

    a {
      color: var(--qkn-red);

      &:hover {
        color: var(--white);
      }
    }
  }
  
  &.primary-eerie-black-invert,
  &.primary-blue-invert {
    border: 1px solid var(--lavender-blue);
    background-color: transparent;
    color: var(--qkn-blurple);

    img {
      margin-right: 8px;
    }
  }
  
  &.primary-eerie-black-invert {
    color: var(--eerie-black);
  }
  

}

.cta-link {
  // Default cta links to blurple theme, then class names below that are appened via contentful 
  // options can override from there 
  text-decoration: underline;
  color: var(--qkn-blurple);
  &:hover,
  &:active {
    color: var(--qkn-blurple);
    background: var(--qkn-light-blurple);
  }
  
  &.link-primary-red,
  &.primary-red {
    color: var(--qkn-red);
    &:hover,
    &:active {
      background: var(--qkn-light-red);
    }
  }

  &.link-primary-blue,
  &.primary-red {
    color: var(--qkn-blurple);
    &:hover,
    &:active {
      background: var(--qkn-light-blurple);
    }
  }
}

// Override footer section to not have deault link coloring
footer .top .right .footer-menu a {
  text-decoration: none;
  color: var(--qkn-light-gray);

  &:hover {
    background: none;
  }
}
// End CTA and link color themeing

button {
  border: 0px;
  border-radius: 400px;
  font-size: 20px;
  white-space: nowrap;
  cursor: pointer;

  @include bp(md-lg, max) {
    font-size: 16px;

    a {
      padding: 16px 28px;
    }
  }

  a {
    padding: 20px 32px;
    text-decoration: none;
    display: block;

    &:hover {
      background: none;
    }
  }



  &.medium {
    font-size: 17px;

    a {
      padding: 12px 24px;
    }
  }

  &.small {
    font-size: 14px;

    a {
      padding: 8px 16px;
    }
  }

  &.media-btn {
    &.medium {
      font-size: 17px;
      padding: 12px 24px;
    }

    &.small {
      font-size: 14px;
      padding: 8px 16px;
    }

    .media-icon {
      height: 12px;
      width: 12px;
    }
  }
}