.agent-home-hero {
  display: none; 
}

.agent-home-page {
  margin: 13px 21px; 

  .search-results {
    padding: 13px; 
    
    .search-result-item {
      display: flex;
      flex-direction: column;
      justify-content: left;
      margin: 13px 0;
  
      .search-result-line-image {
        height: 34px;
        margin-right: 8px;
        padding: 5px;
      }
  
      .search-result-teaser {
        padding: 8px; 
      }
  
      .search-result-heading {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
  
        .search-result-line-image {
          height: 34px;
          margin-right: 8px;
          padding: 5px;
        }
  
        .search-result-link {
          color: var(--qkn-blurple);
  
          &:visited {
            color: var(--qkn-blurple);
          }
  
          &:active {
            color: var(--qkn-blurple);
          }
        }
      }
  
      .search-result-link {
        color: var(--qkn-blurple);
  
        &:visited {
          color: var(--qkn-blurple);
        }
  
        &:active {
          color: var(--qkn-blurple);
        }
        
      }
  
      // flex-basis: 98%; 
      margin-bottom: 13px;      
      margin-right: unset; 
  
      @include bp(mobile) {
        flex-basis: 45%; 
        margin-right: 8px;
      }
    }
  }


  .agent-tiles-area {
    display: none;
    justify-content: space-between;
    flex-direction: column; 

    @include bp(md) {
      flex-direction: row; 
    }

    padding: 13px; 

    h3 {
      font-size: 34px; 
    }

    .agent-tile {
      flex-basis: 97%; 
      margin-bottom: 13px; 

      @include bp(md) {
        flex-basis: 30%;
        margin-bottom: unset; 
      }
       
      padding: 15px; 
      border: solid 1px var(--gray-ee); 
      border-radius: 17px;
      background: var(--card-gray); 

      .agent-line-item {
        margin: 13px 0; 
        padding: 13px; 
        border-bottom: solid 1px var(--gray-aa); 

        &.faq-line-item {
          margin: 0; 
          padding: 8px; 
          border-bottom: unset; 
        }

        h4 {
          font-size: 21px; 
          line-height: 23px; 
        }

        h5 {
          margin: 8px 0;
          font-size: 17px;  
        }

        a {
          color: var(--gray-33); 
        }

        p {
          word-break: break-word; 
        }

        .fi-data {
          dl {
            display: block; 
            margin: 8px 0; 

            dt {
              display: inline; 
            }
            
            dd {
              display: inline;
              margin-left: 13px; 
            }
          }
        }
        
      }
    }
  }

  .agent-alerts-area {
    display: none; 
    padding: 13px; 
    min-height: 120px; 

    .agent-banner-line-item {
      padding: 21px;
      margin: 13px 0;  
      border-radius: 13px;

      a {
        color: var(--gray-33);

        h5 {
          font-size: 15px; 
          @include bp(sm) {
            font-size: 18px; 
          }
          @include bp(md) {
            font-size: 24px; 
          }
        }
      }
    }
  }
}

.tab-container {
  .tab-panes {
    .tab-pane {
      display: none;

      &.active {
        display: block;
      }
    }
  } 
  
  .tabs {
    .tab {
      cursor: pointer; 
      display: inline-block; 
      background: #fefefe;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid var(--black);
      color: var(--black); 
      
      margin-top: 2px;
      @include bp(md) {
        margin-top: unset; 
      }

      &.active {
        background: #fefeaa;
        border: 1px solid var(--qkn-red, #EB0130);
        background: var(--qkn-red-background);
      }
    }
  }
}

