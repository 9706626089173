.search-autocomplete {
  position: absolute;
  width: 400px;
  height: fit-content;
  border: solid 1px var(--qkn-blurple2);
  background: #fdfdfd;
  border-radius: 22px;
  z-index: 300;
  overflow: auto;

  .autocomplete-items {
    padding: 21px;

    .autocomplete-line-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;

      img {
        margin-right: 7px;
      }

      a {
        color: var(--qkn-blurple);
      }
    }
  }
}

