// styles for the feature card slider
#feature-card-slider {
  display: grid;
  grid-template-areas:
    "header"
    "slider"
    "arrows"
    "cta";
  
  @include bp(md) {
    // max-width: 1150px;
    // margin: 0 auto;
    // display: unset;
    // grid-template-areas:
      // "header arrows"
      // "slider slider"
      // "cta cta";
  }

  .slider-tagline {
    grid-area: header;
    letter-spacing: 0;
  }

  .splide__arrows {
    grid-area: arrows;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    
    @include bp(md) {
      margin: 0;
      &.hide-arrows {
        display: none;
      }
    }
  }

  .splide__arrow.splide__arrow--next:enabled:hover,
  .splide__arrow.splide__arrow--prev:enabled:hover {
    background: var(--qkn-blurple, #471CFF);

    img {
      filter: invert(1);
    }
  }

  .splide__arrow {
    position: unset;
    background-color: white;
    border: 1px solid #EAECF7;
    filter: drop-shadow(6px 9px 26px rgba(7, 8, 31, 0.06));

    @include bp(md) {
      position: absolute;
      top: 50%;
    }

    @include bp(lg) {
      // position: absolute;
      // left: unset;
    }
  }

  .splide__arrow--next {
    @include bp(md) {
      right: 1em;
    }
  }

  .splide__arrow--prev {
    @include bp(md) {
      left: 1em;
    }
  }

  .splide__track {
    grid-area: slider;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 64px;

    @include bp(md) {
      width: 80%;
    }

    .splide__slide {
      min-width: 305px;
    }
  }


  .feature-list-card {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius-default;
    height: 100%;

    .top-content {
      order: 2;
      padding: 25px 25px 0px 25px;
      line-height: 1.2;
    }

    .img-contain {
      order: 1;

      .feature-img {
        border-top-left-radius: $border-radius-default;
        border-top-right-radius: $border-radius-default;
        max-width: 100%;
        height: auto;
      }
    }


    .feature-contain {
      order: 3;
      padding: 0px 25px 7px;

      .faux-link {
        display: flex;
      }

      li:not(:last-child) {
        margin-bottom: 10px;
      }

      .feature-icon {
        img {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
      }
    }
  }


  .cta {
    grid-area: cta;
    display: flex;
    justify-content: center;
  }
}