.compact-cards {
  padding-top: 0px;

  &.qkn-mid-blue-background {
    .pcard-contain {
      background-color: var(--qkn-mid-blue-background);
    }
  }

  #compact-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-areas:
      "title title"
      "subline subline"
      "img img"
      "cards cards";
    align-items: start;
    padding: 0px 40px;
    max-width: 500px;
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 64px;

    @include bp(md) {
      grid-template-areas:
        "title subline"
        "img cards";
      max-width: unset;

      .sl-contain {
        flex-direction: column;

        @include bp(sm-md) {
          flex-direction: row;
        }
      }
    }

    .title {
      grid-area: title;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      padding: 0px 20px;
      margin-bottom: 0px;
      text-align: center;

      @include bp(md) {
        width: 82%;
        margin: 0 auto;
        text-align: left;
      }
    }

    .card-img {
      grid-area: img;
      position: relative;
      text-align: center;

      @include bp(md) {
        margin-top: 100px;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 99px;
        left: 0;
        right: 0;
        height: 100px;
        background: linear-gradient(0deg, #DEE5FF 0%, rgba(222, 229, 255, 0.00) 100%);

        @include bp(md) {
          display: none;
        }
      }
    }

    .countdown-timer {
      display: none; //"none" to avoid the timer pop-up before JS loads, managed by JS in Countdown Timer
      justify-self: center;
      grid-area: subline;
      align-self: flex-end;
      max-width: 483px;
      min-width: 314px;
      height: 97px;
      padding: 12px 15px 11px 12px;
      margin-left: -5.3em;

      @include bp(md-lg, max) {
        border-radius: 16px;
        border: 1px solid var(--qkn-mid-blue-background);
        margin-left: 0;

      }
    }

    .sl-contain {
      display: none; //"none" to avoid the sl-contain pop-up before JS loads, managed by JS in Countdown Timer
      grid-area: subline;
      flex-direction: column;
      margin-bottom: 0;
      padding-bottom: 40px;
      font-size: 17px;
      line-height: 1.4;
      text-align: center;
      align-items: center;
      $flair-size: 80px;
      $flair-spacing: 1.3em;

      img {
        height: $flair-size;
        width: $flair-size;
        margin-bottom: 10px;
      }

      // .price-section {
      //   display: inline-flex;
      // }

      @include bp(md) {
        flex-direction: row;
        color: var(--darkGray);
        font-size: 19px;
        line-height: 1.5;
        letter-spacing: -0.23px;
        text-align: left;
        width: 515px;
        margin: 0px auto 10px;
        padding: 0;
        text-align: left;
        align-self: flex-end;
        $flair-size: 100px;

        img {
          height: $flair-size;
          width: $flair-size;
          margin: 0 $flair-spacing 0 0;
        }

        p {
          // padding: 0;
          margin-bottom: 0px;
          max-width: 370px;
        }

        &.flair-right {
          flex-direction: row-reverse;
          text-align: right;

          img {
            margin-left: $flair-spacing;
            margin-right: 0;
          }
        }
      }
    }

    .card-img {

      img,
      video {
        max-width: 90%;
        height: auto;
      }
    }

    .cards {
      grid-area: cards;
      z-index: 2;
      margin-top: -100px;
      padding: unset;

      @include bp(md) {
        margin-top: 0;
        width: 515px;
        margin: 15px auto 0px;
      }

      .product-card {
        padding-top: 0px;

        &.qkn-mid-blue-background {
          background-color: var(--qkn-mid-blue-background);
        }

        &:not(.mini-card) {

          .product-header,
          .price-contain {
            position: relative;

            .btm-border,
            .top-border,
            .middle-border {
              position: absolute;
              width: 85%;
              border: 1px solid var(--qkn-medium-gray2);
            }

            .btm-border {
              bottom: 0px;
            }

            .middle-border {
              bottom: 50%;
            }

            .top-border {
              top: 0px;
            }
          }
        }

        .price-contain,
        .subhead-contain {
          padding: 10px 28px;
        }
        

        .product-header {
          position: relative;
          padding-bottom: 24px;
        }

        .desktop-btn {
          display: none;

          @include bp(md) {
            display: flex;
          }
        }

        .product-ctas {
          align-items: flex-start;
          justify-content: flex-start;

          .cta-link {
            margin-left: 0;
          }
        }

        // tweaks to card parts that are minicard-specific
        &.mini-card {
          .arrow-icon {
            display: none;
          }

          .price-contain .billed {
            display: none;
          }

          .toggle-contain,
          .price-contain,
          .subhead-contain,
          .product-ctas {
            margin-left: 0px;

            @include bp(md) {
              margin-left: 92px;
            }
          }

          .toggle-contain {
            max-width: unset;

            @include bp(md) {
              padding-top: 0px;
              max-width: 326px;
            }
          }

          .product-ctas {
            .cta-link {
              text-align: left;
            }
          }

          .product-header {
            padding-bottom: 0px;
          }
        }
      }
    }
  }
}