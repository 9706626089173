.support-article-main {
  font-family: 'Haffer', sans-serif;
  color: var(--qkn-medium-gray); 
  letter-spacing: -0.2px;
  line-height: 21px;

  &.hidden {
    display: none; 
  }

  h1 {
    letter-spacing: -0.2px;
  }

  .hidden {
    display: none;
  }

  .hidden-card {
    display: none;
  }

  .shown-card {
    display: block;
  }

  .support-article-loading-mesg {
    margin: 170px;
    font-size: 13pt;
    text-align: center;
  }

  .support-article-container {
    width: 95%;
    margin: 0 auto 70px;

    .support-article-header-content {
      margin-top: 75px;
      padding: 13px;

      .title {
        margin-bottom: 48px;
        font-weight: bold;
        font-size: 32px; 
        @include bp(mobile) {
          font-size: 44px;
        }
      }

      .care-categories {
        display: flex; 
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 8px; 
        
        .care-categories__label {
          display: none;
          @include bp(mobile) {
            display: block; 
            margin-right: 13px; 
            color: var(--qkn-light-gray);
          }
        }

        .care-prod-versions-container {
          .care-prod-versions {
            display: flex; 
            flex-direction: row; 
            justify-content: flex-start;
            width: 350px; 
            align-items: center; 

            .prod-ver {
              background-color: var(--qkn-red);
              border-radius: 5px;
              color: #fff;
              display: inline-block;
              font-weight: 700;
              font-size: 10px;
              margin: 2px 4px;
              padding: 2px 4px;
              text-transform: uppercase;
            }
          }
        }
      }

      .vote-count {
        display: flex; 
        flex-direction: column;  
        @include bp(mobile) {
          flex-direction: row; 
        }
        justify-content: flex-start;
        color: var(--qkn-light-gray); 
        
        .date-updated {
          padding-right: 21px;  
          margin: 0 0 8px 0; 
          @include bp(mobile) {
            border-right: solid 2px var(--qkn-light-gray); 
            margin-right: 21px;
          }
        }
      }
    }

    .support-article-body {
      display: flex;
      flex-direction: column-reverse;
      @include bp(md) {
        flex-direction: row;
      }
      justify-content: space-between;
      
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; 

      .support-article-upper-content {
        padding: 15px;
        flex-basis: 75%;

        .qkn-support-page-tab {
          .toggle-container {
            padding: 0px 3px;
            background-color: var(--white);
            border: 1px solid var(--qkn-red, #EB0130);
            border-radius: 7px;
            width: auto;
            width: 250px;

            .support-toggle-img {
              height: 22px;
              margin: unset;
              padding: unset;
              margin-right: 8px;
              position: relative;
              top: 5px;
            }
          }
        }

        ul {
          list-style: disc;
          margin-left: 45px; 
        }

        ol {
          list-style: decimal;
          margin-left: 45px; 
        }

        img {
          margin: 13px 0; 
          max-width: 98%;
        }

        iframe {
          margin: 13px auto;
        }

        p {
          margin: 8px 5px;
          font-size: 17px;
          font-weight: 400; 
        }

        h4 {
          font-size: 20px; 
        }

        .community-content-card { 
          display: none; 
        }

        .cards-wrapper {
          align-items: flex-start;
          flex-direction: column;
          text-align: center;
          
          padding: 0 8px 80px;
          @include bp(mobile) {
            padding: 0 39px 0;
          }
          
          .cards.support-cards {
            grid-template-columns: unset; 
            @include bp(mobile) {
              grid-template-columns: repeat(2, 1fr);
            } 

            align-items: flex-start; 

            .related-articles-area {
              &.related-articles-card {
                display: block; 
              }
              
              height: 100%; 
              background: var(--card-gray); 

              border: solid 1px var(--gray-ee);
              box-shadow: 8px 8px 8px rgba(235, 235, 235, 0.4);
              padding: unset; // 0px 30px 43px 40px;
  
              .tile-head-img {
                margin: unset;
                max-width: unset;
                width: 100%;  
              }
  
              .rel-articles-section {
                margin: 3px 21px; 
              }
    
              .rel-articles-head {
                display: flex; 
                flex-direction: row; 
                align-items: center;
    
                img {
                  flex-basis: 26px; 
                }
    
                h4 {
                  &.related-articles-head-txt {
                    flex-grow: auto; 
                    height: unset; 
                    text-align: left;
                    margin-left: 13px;
                    margin-bottom: unset; 
                  }
                }
              }
    
              .article-links {
                text-align: left;
                margin-bottom: 21px; 
   
                ul.article-links-list {
                  list-style: none;
                  margin-left: 0;

                  li {
                    margin-bottom: 16px; 
                    
                    a {
                      color: var(--qkn-blurple); 
                      text-decoration: none; 
                    }
                  }
                }
              }
            }

          }
        }

        .agent-messages-area {
          display: none; 
          background: var(--card-gray);
          border-radius: 21px;
          padding: 21px;
          border: solid 1px var(--gray-dd);  
          margin-top: 21px; 
            
          .author-notes-wrap, .agent-notes-wrap {
            padding: 13px;
            display: none; 
            > h3 {
              border-bottom: dotted 1px var(--gray-33);
              padding-bottom: 3px; 
            } 
          }  
        }

        .agent-feedback-area {
          display: none; 
          background: var(--card-green);
          border-radius: 21px;
          padding: 21px;
          border: solid 1px var(--gray-dd);  
          margin-top: 21px; 
          
          .feedback-messages-wrap {
            padding: 13px;

            .agent-feedback-item {
              padding: 8px; 
              border-bottom: dotted 1px var(--gray-80);
              margin-bottom: 5px; 
            }

            > h3 {
              border-bottom: dotted 1px var(--gray-33);
              padding-bottom: 3px; 
            }
          }
        }

      }

      .support-article-jump-menu {
        flex-basis: 100%;
        position: relative; 

        @include bp(md) {
          width: unset; 
          align-self: unset; 
          flex-basis: 25%; 
        } 

        .jump-menu-wrapper {
          position: relative; 
          top: 0px; 
          @include bp(md) {
            position: sticky; 
            top: 90px;
          }
        }
      }

      .switch-wrapper {
        .switch {
          border-radius: 3px;
          background: var(--qkn-red);
          height: 100%;
        }
      }

      .support-article-id {
        margin: 17px 0; 
      }
      
      table {
        text-align: center;
        border-collapse: collapse;
        border: solid 1px var(--gray-ee); 
        margin: 21px 0; 
        padding: 5px; 

        th {
          border: solid 1px var(--gray-ee);
          background-color: var(--card-gray);
          padding: 5px;  
        }

        td {
          border: solid 1px var(--gray-ee); 
          padding: 5px; 
          text-align: left;
        }
      }

      /////////////////////////////// 
      // expandos
      ///////////////////////////////

      .uaq_nestedexpando {
        list-style: none;
      }

      .uaq_expandolist {
        list-style: none;
      }

      .uaq_msnav {
        background-image: url('/assets/images/plus-icon.svg'); 
        background-repeat: no-repeat;
        display: inline-block;
      }

      h2.uaq_msnav {
        display: block;
        cursor: pointer;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 135%; /* 25.65px */
        letter-spacing: -0.095px;
      }

      .uaq_arrow_expanded {
        background-position: 0 -191px;
      }

      .uaq_expanded {
        margin-bottom: 10px;
        padding-left: 10px;
      }

      .uaq_msnav {
        position: relative;
        margin: 10px 0;
        font-size: 17px;
        font-weight: 600; 
        line-height: 1.3125;
        background-image: none;
        cursor: pointer;
      }

      h2.uaq_msnav {
        display: block;
        font-size: 17px;
        cursor: pointer;
      }

      .uaq_arrow_expanded:before {
        transform: rotate(90deg);
      }

      .uaq_expanded {
        padding-left: 24px;
      }
    }

    .card-swapper {
      margin-top: 30px;
    }
  }

}

.footnotes-content {
  display: none;
}

// Instructions/body.
.instructions-body {
  h3 {
    height: auto;
    font-size: 26px;
    font-weight: 600; 
    line-height: 120%; // 27px;
    margin: 18px 0;
    letter-spacing: -0.26px; 
  }

  .instructions-header {
    .section-id-area {
      scroll-margin-top: 89px;
    }
  }

  .instruction-step-header {
    color: #F74852;
    font-size: 18px;
    text-transform: uppercase;
    padding-right: 6px;
  }
}

// Jump menu.
.jump-menu-wrapper {
  position: sticky;
  top: 275px;
  position: sticky;
  width: 97%;
  margin: 0 auto;

  padding: 34px 13px;
  border-radius: 16px;
  border: solid var(--gray-ee) 1px; 
  box-shadow: 8px 8px 8px rgba(235, 235, 235, 0.4);

  .jump-menu-header {
    font-size: 14px;
    font-family: "Haffer", sans-serif;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
    margin-left: 13px; 
    color: var(--qkn-blurple); 
    border-bottom: solid var(--bright-gray) 1px; 
    padding: 10px 16px; 
  }

  .jump-menu {
    list-style: none;
    padding-left: 0;

    @include bp(lg) {
      width: inherit; 
      margin: auto 5px auto 5px; 
    }

    li:nth-child(1) {
      margin-top: 18px;
    }

    li {
      font-size: 11pt;
      line-height: 1.625;
      padding: 16px;
      padding-left: 17px;
      border-radius: 4px;
      letter-spacing: -0.02px;

      &:hover {
        background: var(--card-gray); 
        font-weight: 600; 
        letter-spacing: -0.14px; 
      }

      a {
        color: var(--gray-33);
        text-decoration: none;
        display: inline-block;
        width: 100%;

        b {
          font-weight: normal;
          letter-spacing: -0.02px;  
          &:hover {
            font-weight: 600; 
            letter-spacing: -0.14px;  
          }
        }

        &:hover {
          background: var(--card-gray);
        }
      }
    }
  }

}
