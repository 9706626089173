// Global typography defaults. If these need to be overridden in a specific
// instance, it should be done in a "components" partial
$font-size-base: 16; // The default font size for your site. This gets used in

//
// Font-related variables
//
  @font-face {
    font-family: 'Haffer';
    src: url('/assets/styles/fonts/haffer/Haffer-Regular.woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Haffer';
    src: url('/assets/styles/fonts/haffer/Haffer-RegularItalic.woff2');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Haffer';
    src: url('/assets/styles/fonts/haffer/Haffer-SemiBold.woff2');
    font-weight: 600;
    font-style: bold;
  }
  @font-face {
    font-family: 'Haffer';
    src: url('/assets/styles/fonts/haffer/Haffer-SemiBoldItalic.woff2');
    font-weight: 600;
    font-style: italic;
  }
  
  $fontfamily: "Haffer", sans-serif;
  // $fontfamily-bold: 'Haffer SemiBold', sans-serif;
  // $fontfamily-italic: 'Haffer Italic', sans-serif;
  // $fontfamily-alt: "Source Sans Pro", sans-serif;
  $font-weight-light: 300;
  $font-weight-normal: 400;
  $font-weight-semi-bold: 600;
  $font-weight-bold: 700;

*{
  box-sizing: border-box;
}

html {
  // You should ALWAYS have this line, unchanged.
  // The px-to-rem function depends on it.
  font-size: calc($font-size-base / 16) * 100%;
}

body {
  font-family: $fontfamily;
}
//Mobile first->desktop second
h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 1;
  margin-bottom: 16px;

  @include bp(md) {
    font-size: 64px;
    letter-spacing: -3px;
  }

  @include bp(md-lg) {
    margin-bottom: 20px;
  }

  sup {
    position: relative;
    font-size: 28px;
    top: -16px;

    @include bp(md) {
      position: relative;
      font-size: 36px;
      top: -28px;
    }
  }
}

h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  margin-bottom: 16px;

  @include bp(md) {
    font-size: 48px;
    margin-bottom: 20px;
  }

  sup {
    position: relative;
    font-size: 24px;
    top: -10px;

    @include bp(md) {
      position: relative;
      font-size: 28px;
      top: -16px;
    }
  }
}

h3 {
  font-family: "Haffer";
  font-weight: 600;
  font-size: 28px;
  line-height: 1;
  margin-bottom: 20px;

  @include bp(md) {
    font-size: 36px;
  }

  sup {
    position: relative;
    font-size: 18px;
    top: -10px;

    @include bp(md) {
      position: relative;
      font-size: 22px;
      top: -12px;
    }
  }
}

h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 24px;

  @include bp(md) {
    font-size: 30px;
  }

  sup {
    position: relative;
    top: -6px;
    font-size: 18px;

    @include bp(md) {
      position: relative;
      font-size: 20px;
      top: -9px;
    }
  }
}

h5 {
  font-weight: 600;
  font-size: 13px;
  line-height: 31px;

  @include bp(md) {
    font-size: 24px;
  }

  sup {
    position: relative;
    top: -3px;
    font-size: 12px;

    @include bp(md) {
      position: relative;
      font-size: 13px;
      top: -10px;
    }
  }
}

blockquote {
  line-height: 1.5;
}


b,
strong {
  font-weight: $font-weight-bold;
  font-weight: 600;
}

i,
em {
  font-style: italic;
}

u {
  text-decoration: underline;
}

p.lead {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
}

sup {
  position: relative;
  font-size: 12px;
  top: -4px;
}

sub {
  position: relative;
  font-size: 0.8em;
  top: 0.2em;
}

a {
  text-decoration: none;
  color: var(--qkn-blurple);

  &:hover {
    background: var(--qkn-light-blurple);
  }
}

.undecorated {
  text-decoration: none;
}

.h1-wrapper {
  font-size: 64px;

  @include bp(sm, max) {
    font-size: 40px;
  }
}

.h2-wrapper {
  font-size: 48px;

  @include bp(sm, max) {
    font-size: 32px;
  }
}

.h3-wrapper {
  font-size: 36px;

  @include bp(sm, max) {
    font-size: 28px;
  }
}

.h4-wrapper {
  font-size: 30px;

  @include bp(sm, max) {
    font-size: 24px;
  }
}

.h5-wrapper {
  font-size: 24px;

  @include bp(sm, max) {
    font-size: 13px;
  }
}
