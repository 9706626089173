@mixin transition-alpha {
    transition-timing-function: ease-out;
    transition-duration: $transition-duration-default;
    transition-property: background-color border-color color;
}
@mixin transition-beta {}
@mixin transition-link($state: "rest", $transitionProfile: "alpha") {
    @if $transitionProfile == "alpha" {
        @include transition-alpha;
    } @else if $transitionProfile == "beta" {
        @include transition-beta;
    }
    @if $state == "hover" {
        transition-duration: 0s;
    }
}
@mixin image-slide($animationName, $position) {
    @keyframes #{$animationName} {
        0% {
            transform: translateY($position);
        }
        100% {
            transform: translateY(0%);
        }
    }
}

.flipped {
  transform: scalex(-1);
}