.support-hero {
  &.qkn-opt-hero {
    background: unset; 
    margin: unset; 
    @include bp(md-lg) {
      max-width: 75%;
      margin: 0 auto; 
    }
  }

  .care-search-form {
    &.qkn-opt-care-search {
      flex-direction: column;
      align-items: flex-start;
    } 
  }

  ///////  optimizely tests QWEB-431
  .qkn-opt-care-search {
    position: relative;
    height: 300px;
    display: flex; 
    flex-direction: column; 
    align-items: flex-start; 

    .qkn-opt-qkn-logo {
      position: absolute;
      right: 221px;
      top: 21px; 
    }
 
    .search-form-wrap {
      border-radius: 400px;
      border: 1.5px solid var(--qkn-medium-gray2);
      background: var(--white);
      box-shadow: 6px 9px 26px 0px rgba(7, 8, 31, 0.06);
    } 

    .search-form-items .search-form-wrap .hero-search-form .support-search-submit {
      &.qkn-opt-test {
        width: 100%;
        @include bp(sm-md) {
          width: 45px;
        }
        
        height: 45px;
        flex-basis: unset;
      }
    }

    // .hero-search-form {
    //   button {
    //     &.support-search-submit {
    //       &.qkn-opt-test {
    //         width: 24px;
    //         height: 24px;
    //       }
    //     }
    //   }
    // }

    .search-form-items {
      z-index: 3; 
    }

  }
}