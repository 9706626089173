section.social-proof-bar {
    .container {
        border-radius: 16px;
        padding: 16px 0;

        @include bp(sm-md) {
            padding: 24px 0;
        }

        .headline {
            text-align: center;
            color: var(--qkn-light-gray);
        }

        .logos {
            grid-column-gap: 12px;
            grid-row-gap: 4px;
            flex-flow: wrap;
            align-content: flex-start;
            justify-content: space-evenly;
            align-self: center;
            align-items: center;
            display: flex;

            margin: 8px;
            margin-bottom: 0;

            @include bp(sm-md) {
                grid-row-gap: 12px;
            }

            li {
                max-height: 396px;
                max-width: 128px;
                @include bp(sm-md) {
                    max-height: 650px;
                    max-width: 210px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    /* Desaturation Feature */
    &.desaturate .container .logos {
        filter: grayscale(1);
    }
}