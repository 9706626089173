
.cta-pane-container {
  margin: 21px; 
  display: flex;

  flex-direction: column;  
  @include bp(md) {
    flex-direction: row;
  }

  justify-content: space-between;

  background-color: var(--card-gray); 
  border-radius: 24px;

  .compact-cards #compact-cards {
    background: var(--card-gray); 
    display: block; 
    padding: 0; 

    @media screen and (min-width: 1024px) {
        display: block; 
        // grid-template-areas: none; 
        //  "title subline" 
        //  "img   cards  ";
        max-width: unset;
        padding: 0; 
    }

    .product-card {
      background-color: var(--card-gray); 
    }
  }

  .cta-left-area {
    display: flex; 
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 30px; 
    margin-top: 43px; 

    .cta-left-top-headers {
      height: 250px; 
      @include bp(md) {
        height: unset; 
      }

      font-size: 19px;
      h3 {
        font-size: 36px; 
      }
    }

    .shown-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .hidden-card {
      display: none;
    }

    .cta-left-bottom-tabs {
      position: relative; 

      .cta-bottom-arrow {
        position: absolute;
        top: 18px;
        left: -37px;
      }

      .compact-cards {
        padding: unset; 
        background-color: unset; 
      }

      .product-card {

        .flex-wrap {
          .toggle-contain {
            .toggle-container {
              width: 250px;  
  
              &.toggle-container-care {
                border: 1px solid var(--qkn-red); 
              }
  
              .switches-container {
                .switch-wrapper {
                  .switch {
                    background: var(--qkn-red-background);
                    border: 1px solid var(--qkn-red);
                    outline: none;
  
                    .care-switch-item {
                      color: #000;
                    }
                  }
                }
              }
   
            }
          }
  
          .card-swapper {
            // min-height: 165px; 
            
            .shown-card {
              padding: 21px 21px 21px 0px;
  
              .cta-tab-content-buttons {
                display: flex;
                align-items: center;
                margin: 13px 0 0;
  
                .tab-content-btn-primary {
                  color: var(--qkn-red);
                  background: #fff;
                  border-radius: 24px;
                  border: 1px solid var(--qkn-red);
                  font-weight: 700;
                  margin: 8px 13px 8px 0;
                  height: 48px;
                  padding: 12px 24px;
                  font-size: 17px;
                  line-height: 1.3;
  
                  &:hover {
                    background: var(--qkn-red);
                    color: #fff;
                    border: 1px solid var(--qkn-red);
                  }
                }
  
                .tab-content-link {
                  color: var(--qkn-blurple); 
                }
  
              }
            }
          }
        }
      }

    }

  }

  .cta-image-wrap {
    img {
      float: right; 
      width: 100%;
      @include bp(mobile) {
        width: unset;
        display: block; 
        margin: 0 auto; 
        float: unset; 
      }
      @include bp(navbar-breakpoint) {
        float: right;
        width: 100%;
        margin: unset;  
      }
    }
  }
}

