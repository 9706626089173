.divider {
  display: block;
  width: 80%;
  margin: 0 auto;
  padding: 0;
  position: relative;

  &.show-border {
    &:after {
      content: "";
      position: absolute;
      border-top: 1px solid var(--bright-gray);
      width: 100%;
      top: 50%;
    }
  }

  &.top-small {
    padding-top: 50px;
  }

  &.top-medium {
    padding-top: 75px;
  }

  &.top-large {
    padding-top: 100px;
  }

  &.bottom-small {
    padding-bottom: 50px;
  }

  &.bottom-medium {
    padding-bottom: 75px;
  }

  &.bottom-large {
    padding-bottom: 100px;
  }
}