.qkn-opt-contact-modal-wrapper {
  .qkn-opt-contact-modal {
    padding: 32px;
    width: fit-content; 
    max-width: unset;

    .contact-modal--body {
      overflow-y: unset;

      .qkn-opt-contact-header {
        font-size: 32px; 
      }

      h4 {
        color: var(--qkn-medium-gray);
        font-size: 17px;
        font-weight: 600;
      }

      // Quicken optimizely
      .qkn-opt-phone-num {
        display: flex;
        flex-direction: row;
        align-items: top;
        gap: 13px;

        .qkn-opt-phone-wrap {
          .qkn-opt-phone {
            color: var(--qkn-medium-gray);
            font-size: 21px;
            margin: 8px 0; 
          }
      
          .qkn-opt-wait-time {
            color: var(--qkn-light-gray);
            text-overflow: ellipsis;
            font-size: 16px;
      
            .qkn-opt-wait-time-duration {
              color: #FE5B42;
              text-overflow: ellipsis;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }

      .qkn-opt-get-in-touch-tiles {
        display: flex;
        flex-direction: row;

        .qkn-opt-in-touch-tile {
          display: flex;
          gap: 21px;
          border-radius: 16px;
          padding: 24px 24px 24px 16px;
          align-items: center; 

          .qkn-opt-desc {
            h3 {
              font-size: 23px
            }

            .qkn-opt-contact-msg {
              color: var(--qkn-light-gray);
              font-size: 16px;

              .qkn-opt-wait-time-duration {
                color: #32A45F;
                font-size: 16px;
              }
            }
          }

          &.recommended {
            border: 2px solid var(--qkn-blurple);
            position: relative;

            .recommended-badge {
              display: flex;
              flex-direction: row;
              justify-content: space-around;

              position: absolute;
              width: 140px;
              top: -16px;
              text-align: center;
              border: solid 2px var(--qkn-blurple);
              border-radius: 6px;
              background: var(--white);
              padding: 5px;
              left: calc(50% - 75px);
              font-size: 14px;
              line-height: 120%;
              color: var(--qkn-blurple);

              .item-badge-image {
                margin-right: 5px;
              }
            }
          }

          .qkn-opt-chat-now-btn {
            display: flex;
            align-items: center;
            height: 50px;
            padding: 12px 24px;
            border-radius: 400px;
            background: var(--qkn-blurple);
            color: var(--white); 
          }
        }
      }
    }
  }
}