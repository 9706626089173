.login-content-area {
  width: 45%;
  margin: 0 auto; 
  
  .agent-login-form {
    display: none; 
    margin: 44px;

    display: flex;
    flex-direction: column; 
    justify-content: space-evenly;

    .agent-login-button {
      background: var(--qkn-red);
      color: var(--white);
      padding: 8px 34px;  
      font-weight: 600; 
      border: solid 1px var(--qkn-red); 
      border-radius: 35px; 
      font-size: 16px;
    
      &:hover {
        cursor: pointer; 
      }
    } 
    
    .form-item {
      margin: 8px; 
      label {
        display: flex;
        justify-content: space-evenly;
        align-items: center; 
 
        input[type="text"], input[type="password"] {
          flex-basis: 80%;  
          height: 45px;  
          border-radius: 13px;
          margin-left: 13px; 
          border: solid var(--gray-99) 1px; 
          padding: 8px; 
          font-size: 16px;
          font-weight: 400;   
        }
      }
    }
  }  

  .agent-sign-out-button {
    padding: 21px;

    input {
      background: var(--qkn-blurple);
      color: var(--white); 
      padding: 8px 34px; 
      font-weight: 600; 
      border-radius: 35px; 
      font-size: 16px;  
      border: solid 1px var(--qkn-blurple); 
      
      &:hover {
        cursor: pointer; 
      }
    } 
  } 
  
  .login-error {
    color: var(--qkn-red);
    text-align: center;  
  }
}


