
#support-page-wrapper {
  background-color: var(--white);
  max-width: $max-width;
  min-width: $min-width;
  margin: 0 auto;
  font-family: "Haffer";
  font-size: 16px;
}

.support-front-section {
  margin-top: 40px;  
  color: var(--qkn-light-gray); 
  h1, h2, h3, h4 {
    color: var(--qkn-medium-gray); 
  }
}

.qkn-red-span {
  color: var(--qkn-red);
}

#genesys-messenger {
  display: none; 
}

.intercom-lightweight-app-launcher, .intercom-container {
  display: none; 
}

.intercom-namespace .intercom-dfosxs {
  right: 18px !important; 
}

a:hover {
  background: unset; 
}

