$max-width: 1440px;
$min-width: 320px;

$breakpoints: (
  sm: (
    min: 390px,
    max: 389px
  ),
  mobile: (
    min: 576px,
    max: 575px
  ),
  sm-md: (
    min: 800px,
    max: 799px
  ),
  md: (
    min: 1024px,
    max: 1023px
  ),
  navbar-breakpoint: (
    min: 1146px,
    max: 1145px
  ),
  md-lg: (
    min: 1240px,
    max: 1239px
  ),
  lg: (
    min: $max-width,
    max: calc($max-width - 1px)
  )
);


@mixin bp($breakpoint, $direction: min) {

  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-values: map-get($breakpoints, $breakpoint);
    $breakpoint-min: map-get($breakpoint-values, min);
    $breakpoint-max: map-get($breakpoint-values, max);

    @if $direction == min {
      @media (min-width: $breakpoint-min) {
        @content;
      }
    }

    @else {
      @media (max-width: $breakpoint-max) {
        @content;
      }
    }
  }

  @else {
    @if $direction == min {
      @media (min-width: $breakpoint) {
        @content;
      }
    }

    @else {
      @media (max-width: $breakpoint) {
        @content;
      }
    }
  }
}


// Example usage:

// Basic mobile first type usage:
// .button {
//   width: 100%;
//   @include bp(sm) {
//     width: 50%;
//   }
//   @include bp(lg) {
//     width: 200px;
//   }
// }

// Pass max if you need to do max-width instead of min-width
// .button {
//   width: 200px;
//   @include bp(md, max) {
//     width: 100%;
//   }
// }

// Allows you to pass a custom px value if the designs necessitate it as well
// .button {
//   width: 200px;
//   @include bp(400px, max) {
//     width: 50%;
//   }
// }