.quad-box-hero {
    $margin-top-button: 29px;
    margin: auto;
    max-width: 1060px;

    .component-padding {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      text-align: center;
      margin: 0 20px;
      padding: 32px 15px;

      & > h2 {
        font-size: 40px;
      }
      .rich-text p,
      .top-right p {
        line-height: 1.5;
        font-size: 17px;
        color: var(--qkn-light-gray);
        @include bp(md) {
          font-size: 23px;
        }
      }

      .cta-btn {
        display: block;
        margin: $margin-top-button auto;
      }

      & > img {
        object-fit: cover;
        object-position: top;
        width: 100%;
        height: 100%;
        border-radius: $border-radius-default;
        margin-bottom: 10px;

        &:first-of-type {
          max-height: 40vw;
        }
      }
    }

    @media (min-width: 575px) {
      .component-padding {
        grid-template-columns: 1fr 1.54fr;
        grid-column-gap: 20px;
        grid-row-gap: 78px;
        text-align: left;
        margin: auto;
        padding: 64px 15px;

        & > h2 {
          padding: 0;
          font-size: 56px;
        }
        & > img {
          object-fit: contain;
          max-height: 35vw;
        }

        .top-right {
          padding: 0 10% 0 17%;
        }

        .cta-btn {
          margin: $margin-top-button 0 0;
        }
      }
    }
  }
