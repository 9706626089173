.support-recent-updates-section {
  padding: 21px; 
  .recent-updates-tiles {
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .recent-updates-tile {
      display: flex; 
      flex-direction: column; 
      justify-content: space-between;
      flex-basis: 98%;

      @include bp(md) {
        flex-basis: 48%; 
      }

      padding: 34px; 
      margin-bottom: 34px; 

      border-radius: 18px;
      background: var(--card-gray); 

      .recent-update-item {
        margin: 13px; 
        a {
          color: var(--qkn-blurple); 
          text-decoration: underline;
        }
      }
      
      h3 {
        font-size: 21px;   
        color: var(--qkn-medium-gray); 
      }
    }
  }
}

