section.fi-flow-hero {
  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: $border-radius-default;
    gap: 24px;

    @include bp(mobile) {
      flex-direction: row;
      justify-content: flex-start;
    }

    &>div {
      text-align: center;
      padding: 0 16px;
      @include bp(mobile) {
        text-align: left;
        width: 50%;
      }

      /* Left/Top half (headline) */
      &:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        padding-top: 32px;
        @include bp(mobile) {
          padding: 64px 0 64px 50px;
        }
        @include bp(sm-md) {
          padding-left: 100px;
        }

        &> {
          h2 {
            margin: 0;

            @include bp(sm-md) {
              font-size: 72px;
            }
          }

          p {
            font-size: 19px;
          }

          img {
            margin: 0 auto;

            @include bp(mobile) {
              margin: 0;
            }
          }
        }
      }

      /* Right/Bottom half (form) */
      &:last-child {
        $padding-right-mobile: 48px;
        $max-width-form: 396px;
        padding-bottom: 16px;
        position: relative;

        @include bp(mobile) {
          padding: 64px $padding-right-mobile 64px 0;
        }

        div {
          border-radius: 8px;
          background: var(--white);
          box-shadow: 6px 9px 26px 0px rgba(7, 8, 31, 0.06);
          padding: 24px 20px;
          position: relative;
          z-index: 2;

          @include bp(mobile) {
            max-width: $max-width-form;
            min-width: 250px;
          }

          &> {
            img {
              margin: 0 auto 16px auto;
              display: block;
            }

            .formHeadline {
              margin: 0 13px 19px;
              font-size: 17px;
              font-weight: 600;
              line-height: 140%;
            }

            form {
              border-top: var(--qkn-medium-gray2) 1px solid;

              &> {
                label {
                  display: block;
                  margin: 16px 0 8px;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 150%;
                }

                label,
                .help {
                  text-align: left;
                }

                input,
                button {
                  width: 100%;
                }

                input {
                  border: 1px solid var(--qkn-border-gray1);
                  border-radius: 4px;
                  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);

                  display: flex;
                  height: 45px;
                  padding: 12px 16px;
                  align-items: center;
                  gap: 8px;
                  align-self: stretch;
                }

                input,
                .help {
                  margin-bottom: 16px;
                }

                .help {
                  color: var(--qkn-medium-gray);
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 150%;

                  &>span {
                    display: none;

                    &.default {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }

        &> img {
          position: absolute;
          top: 0;
          height: 100%;
          object-fit: cover;
          left: calc(min($max-width-form, 50vw - $padding-right-mobile) /2);

          @include bp(mobile, max) {
            display: none;
          }
        }

        $map: (
          ('class': 'empty',
            'color': red),
          ('class': 'valid',
            'color': green),
          ('class': 'validating',
            'color': gray),
          ('class': 'invalid',
            'color': red)
        );

        @each $item in $map {
          $color: map-get($item, 'color');
          $class: map-get($item, 'class');

          &>form[data-state="#{$class}"] {
            color: $color;

            input {
              border-color: $color;
            }

            .help>span {
              color: $color;
              display: none;

              &.#{$class} {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}