section.spotlights {
    .content {
        flex-direction: column;
        overflow: unset;
        padding: 0px 16px;
        border-radius: $border-radius-default;
        color: var(--qkn-light-gray);

        & > p {
            margin: auto;
            font-size: 16px;
        }

        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: 24px;
            margin: 0px 16px;

            li {
                width: 45%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 18px;

                @include bp(sm-md) {
                    width: 30%;
                }
                @include bp(md) {
                    width: 15%;
                }

                img {
                    width: auto;
                    height: auto;
                    object-fit: contain;
                }

                p {
                    font-weight: 400;
                    font-size: 14px;
                    width: 100%;
                    line-height: 21px;

                    &.headline {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22.4px;
                    }
                }
            }
        }
    }

    &[data-image-desaturate="true"] .content ul li img {
        filter: saturate(0%);
    }
    &[data-text-alignment="left"] .content ul li {
        align-items: flex-start;
        text-align: left;
    }
    &[data-text-alignment="center"] .content ul li {
        align-items: center;
        text-align: center;
    }
    &[data-text-alignment="right"] .content ul li {
        align-items: flex-end;
        text-align: right;
    }
}