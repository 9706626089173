.secondary-claim-cta {
    background: var(--white);
    border-radius: 16px;
    padding: 24px;

    & > div {
        display: flex;
        column-gap: 16px;
        align-items: center;
        line-height: 21px;

        img {
            max-width: 46px;
            max-height: 46px;

            @include bp(mobile) {
                max-width: 52px;
                max-height: 52px;
            }
        }

        dl {
            dt {
                font-weight: 600;
                color: var(--qkn-medium-gray);
            }
            dd {
                color: var(--qkn-light-gray);

                button {
                    margin-top: 12px;

                    @include bp(mobile) {
                        margin-top: 16px;
                    }
                }
            }
        }
    }

    & > small {
        display: block;
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid var(--qkn-medium-gray2);
        line-height: 18px;
        font-size: 13px;
        color: var(--qkn-light-gray);
    }
}