// css options chosen in contentful
.list-component {
  &.gray {
    .item-content {
      color: var(--qkn-light-gray);
    }
  }
    
  &.black {
    .item-content {
      color: var(--darkGray);
    }
  }

  &.font-large {
    .item-content {
      font-size: 19px;
    }
  }
}

// component styles
.list-component {
  padding: 16px;

  @include bp(mobile) {
    padding: 40px 48px;
  }

  .title {
    padding-bottom: 20px;

    @include bp(mobile) {
      text-align: center;
      padding-bottom: 30px;
    }
  }

  .list-contain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-wrap: wrap;
  
    @include bp(mobile) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px 24px;
    }
  }

  .item {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    width: 100%;

    @include bp(mobile) {
      align-items: center;
      width: auto;
    }

    .item-img {
      margin-top: 4px;
      flex-basis: 45px;
      flex-shrink: 0;
      text-align: center;

      @include bp(mobile) {
        margin-top: 0px;
        flex-basis: unset;
        flex-shrink: unset;
        text-align: unset;
      }

      .img {
        width: 45px;
        height: auto;
      }
    }

    .item-content {
      line-height: 135%;
      letter-spacing: -0.095px;
      flex-grow: 1;
      font-size: 17px;

      @include bp(mobile) {
        flex-grow: unset;
      }
    }
  }
}