.modal-overlay {
  position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    outline: 0;
    background-color: rgba(0,0,0,.45);
    align-items: center;
    justify-content: center;
  &.show {
    display: flex;
  }
  &.fade-in {     
    animation: fadeIn 1s; 
  } 
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .modal-dialog {
    padding: 20px 15px;
    
    .modal-content {
      position: relative;
      display: table;
      margin: 0 auto;
      width: auto;
      pointer-events: auto;
      background-color: var(--white);
      border: 1px solid rgba(0,0,0,.2);
      box-shadow: rgba(0,0,0,.34) 1px 1px 62px 0px;
      border-radius: 10px;
      .modal-header {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        
        button.modal-close {
          padding: 0;
          background-color: transparent;
          border: 0;        
          appearance: none;
          cursor: pointer;
          font-size: 16px;
          font-weight: 800;
          z-index: 5;
          position: absolute;
          top: 12px;
          right: 12px;

          img {
            width: 16px;
            height: 16px;
          }
        }    
      }
      .modal-body {
        position: relative;
        padding: 2rem;

        img {
          max-width: 100%;
          height: 100%;
          max-height: calc(100vh - 115px);
        }

        .embed-responsive {        
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          padding: 0;
          overflow: hidden; 
          
          &:before {
            display: block;
            content: "";
            padding-top: 56.25%;
          }
          
          iframe {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          }
        }
      }
    }
  }
  &.media-video  {
    .modal-dialog {
      width: 100%;
      max-width: 700px;

      .modal-content {
        width: 100%;

      }
    } 
  }
}
