.quicken-support-popup-modal header,
.quicken-support-popup-modal .modal-header {
  padding-left: 0;
  padding-right: 0;
  @include bp(sm) {
    padding: 2em 1.5em;
  }
}

.quicken-support-popup-modal .modal-body {
  padding: 0em;
}

.quicken-support-popup-modal .modal-body .item-list ul {
  padding: 1em 1em 0.5em 1em;
  margin-bottom: 0;
}

.quicken-support-popup-modal.modal-box.modal-box--padded {
  border-radius: 20px;
  max-width: 768px;
  padding: 0;

  .support-modal-close {
    line-height: 1;
    position: absolute;
    right: 18px;
    top: 18px;
    z-index: z(dropdown);

    &:before {
      content: '\2715';
      color: #484848;
      display: inline-block;
      font-family: sans-serif;
      font-size: 18px;
      font-weight: 600;
    }
  }

  header,
  .modal-header {
    background-color: #F6F7F8;
    border: none;
    padding: 0;
    margin-bottom: 40px;

    h2 {
      color: #22324F;
      font-family: 'Haffer', sans-serif;
      font-size: 26px;
      font-weight: 600;
      height: 100%;
      padding: 14px;
      margin-bottom: 0;
      margin-top: 0;
      text-align: center;
    }
  }

  .modal-body {
    @media (min-width: 768px) {
      padding: 0 4rem;
    }
  }

  .modal-body ul {
    padding-top: 0;
    list-style: none;
    font-size: 18px;
  }

  .modal-body ul li {
    position: relative;
    margin: 0;
    margin-bottom: 25px;

    a {
      color: var(--qkn-blurple);
      display: inline-block;
      font-family: 'Haffer', sans-serif;
      font-size: 16px;
      line-height: 1.6;

      &.new,
      &.ongoing {
        padding-left: 98px;
      }

      &::before {
        border-radius: 25px;
        font-size: 14px;
        height: 28px;
        left: 0;
        line-height: 2;
        margin-right: 20px;
        position: absolute;
        text-align: center;
        text-decoration: none;
        width: 85px;
      }

      &.new::before {
        content: 'New';
        color: #22324F;
        background: rgba(2,205,171,.30);
      }

      &.ongoing::before {
        content: 'Ongoing';
        color: #22324F;
        background: rgba(244,201,58,.30);
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .interlay-footer .btn {
    font-weight: 16px;
    text-transform: uppercase;
    height: 44px;
    padding: 10px 12px 6px 12px;
    font-weight: 600;
  }

  .modal-footer {
    padding: 0 1em 1em;
    text-align: right;
    @media (min-width: 768px) {
      padding: 0 2.2em 2.2em;
    }

    .js-modal-close {
      background-color: var(--qkn-blurple);
      border-radius: 20px;
      color: #FFFFFF;
      font-size: 13px;
      font-weight: 800;
      height: 34px;
      line-height: 1.6;
      width: 90px;
    }

    .btn.btn-do-not-show-again {
      color: var(--qkn-blurple);
      font-family: 'Haffer', sans-serif;
      font-size: 13px;
      font-weight: 400;
      padding-right: 9px;
      text-transform: none;
    }
  }
}

/**
 * Notification form.
 */
.quicken-support-popup-modal .notification-signup-form {
  padding-left: 0;
  padding-right: 0;

  @include bp(sm) {
    padding: 0.5em 1.5em;
  }
}

.quicken-support-popup-modal .notification-signup-form h3 {
  margin-top: 15px;
}

.notification-signup-form .form-item-email input {
  width: 100%;
}

.notification-signup-form input[type=checkbox] {
  margin-top: 0;
}

.contact-modal-wrapper {
  display: none;
  position: fixed;

  height: 100%;
  width: 100%;

  top: 0px;
  left: 0px;

  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);

  &.visible {
    display: block; 
  }
  
  &.hidden {
    display: none; 
  }

  .visible {
    display: block; 
  }

  .hidden {
    display: none; 
  }
}

.contact-modal-wrapper.visible {
  display: flex;
  z-index: 2;
}

.contact-modal {
  z-index: 2;
  width: 100%;
  max-width: 450px;
  max-height: calc(100vh - 139px);
  margin-top: 139px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 5px;
}

.contact-modal--body .help-text {
  display: none;
}

.contact-modal--header,
.contact-modal--body > div {
  padding: 10px;
}

.contact-modal--body {
  max-height: calc(98vh - 180px);
  overflow-y: auto;
}

.contact-modal--results {
  display: none;
}

.contact-modal--header {
  font-size: 1.2em;
  padding-bottom: 5px;

  .modal-title {
    margin-bottom: 10px;
  }
}

.contact-modal--header .modal-close {
  color: #cccccc;
  cursor: pointer;
}

div.contact-modal--search {
  padding-top: 0;
  position: relative;
}

.contact-modal--search .intro {
  margin-bottom: 10px;
}

.contact-modal--search .input-group {
  display: flex;
}

.contact-modal--search input {
  border: 1px solid var(--qkn-blurple);
  font-size: 1.3em;
  flex-grow: 1;
}

.contact-modal--search button {
  background-color: var(--qkn-blurple);
  color: white;
  padding: 9px;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 5px;
  border: none;
  border-radius: 5px;
}

.contact-details div {
  padding-left: 15px;
  padding-right: 15px;
}

.initial.contact-modal--split,
.entitled.contact-modal--split {
  padding-top: 10px;
  border-top: 1px solid var(--qkn-blurple);
}

.contact-modal--search .autocomplete {
  position: absolute;
  z-index: 1000;
  width: calc(100% - 60px);
  background: white;
  border-color: var(--card-gray);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.contact-modal--search .autocomplete--item a {
  display: block;
  padding: 7px 20px;
  border-top: 1px solid var(--card-gray);
  transition: background 0.2s cubic-bezier(0.19, 1, 0.22, 1), color 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  text-decoration: none;
  color: var(--qkn-blurple); 

  &:hover,
  &:focus {
    background-color: var(--qkn-blurple);
    color: var(--card-gray);
  }
}

.contact-modal--body .contact-modal--entitlement {
  color: #4A4A4A;
  font-weight: 300;
  padding-bottom: 25px;

  .header {
    display: block;
    padding-top: 10px;
    font-size: 1.5em;
  }
}

.contact-modal--results {
  ul {
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
  }

  .suggestion {
    margin-top: 1rem;
    margin-bottom: 1rem;
    a {
      color: var(--qkn-blurple); 
    }
  }

  .suggestion:not(.suggestion--primary) {
    margin-left: 18px;
  }

  .suggestion .likes::before {
    content: "\f087";
    font-family: Font Awesome,sans-serif;
    margin-right: 0.5rem;
  }

  .suggestion--primary {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    padding: 16px;
    list-style-type: none;
  }

  .suggestion--primary p {
    font-size: 0.9em;
  }

  .suggestion--primary a:first-of-type {
    font-size: 1.5em;
    font-weight: 500;
  }

  .likes {
    display: block;
  }
}

.contact-modal--chat {
  display: none;
}

.contact-modal--phone-area, .contact-modal--chat-area {
  display: none; 
}

.contact-modal--split > div {
  font-size: 1em;
  margin-bottom: 15px;
}

.refine-search {
  padding-top: 10px;
}

.contact-modal--header {
  position: relative;

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.contact-modal--split .contact-heading {
  font-size: 1.25em;
  margin-bottom: 10px;
}
.contact-modal--split .contact-subheading {
  font-size: 16px;
}
.contact-modal--split .contact-details {
  font-size: 13px;
}

.contact-modal--split.entitled {
  .entitled-wrapper {
    display: flex;
  }
}

.intro {
  font-size: 1.25rem;
}

.content-modal--split {
  border-bottom: 1px solid var(--qkn-blurple);
  width: 100%;
  margin: auto;

  h3 {
    margin-top: 10px;
  }
}

.help-text {
  padding-top: 15px;
}

.contact-details {
  width: 100%;
  margin: auto;
  text-align: center;
  
  div {
    text-align: left;
  }
}

.contact-modal--split .contact-sections {
  margin-bottom: 5px;
}

.contact-modal--split button {
  background: var(--qkn-blurple);
  border: 1px solid var(--qkn-blurple);
  border-radius: 5px;
  color: var(--white);

  text-transform: uppercase;
  font-weight: 300;
  width: 150px;

  padding: 8px;
  margin: 10px 30px 0px;
  font-size: 1.2em;

  &.contact-button--inverse {
    background: var(--white);
    color: var(--qkn-blurple);
    &.wider {
       width: 175px; 
    }
  }
}

.contact-modal--split button.disabled {
  border: 1px solid var(--gray-ea);
  color: var(--gray-ea);
  pointer-events: none;
}

.contact-modal--split span.disabled {
  color: var(--gray-ea);
}

.contact-modal--split button.show-phone {
  display: none;
}

.contact-modal--split .phone {
  display: none;
  justify-content: center;
  align-items: center;

  font-size: 1.5em;
  font-weight: 600;
}

.contact-modal--split .phone::before {
  content: " ";
  display: inline-block;

  background-image: url("/sites/all/themes/custom/quicken_2014/images/svg/care-icon--telephone-grey.svg");
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  margin: 10px 10px 0px 0px;
  vertical-align: text-bottom;
}

.contact-modal .modal-title {
  flex-grow: 1;
}

.contact-modal .chatlinkcontainer {
  position: static;
  display: block;
}

