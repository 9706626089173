.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}

.qkn-chatgpt-modal {
  font-family: Haffer, sans-serif;
  border-radius: 13px; 
}

.qkn-chatgpt-modal .qkn-red {
  color: var(--qkn-red);
}

.qkn-chatgpt-modal .btn-primary {
  border-radius: 50px;;
  background-color: var(--qkn-fi-flow-blue);
  width: 100%;
  border: solid 1px var(--blurple);
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  min-width: 148px;
  color: var(--blurple);
}

@media (min-width: 36rem) {
  .qkn-chatgpt-modal a.btn-primary {
    width: auto;
  }
}

.qkn-chatgpt-modal .btn-primary:focus,
.qkn-chatgpt-modal .btn-primary:hover {
  background-color: var(--qkn-fi-flow-blue);
}

.qkn-chatgpt-modal {
  font-family: 'Haffer', sans-serif;
  .edit-chatgpt-answer {
    margin-top: 8px; 
    label {
      font-weight: 600; 
    }

    .chatgpt-answer {
      margin-top: 5px; 
    }

    h3 {
      font-size: 17px; 
      margin-bottom: none; 
      margin: 13px 0; 
    }

    h4 {
      font-size: 15px;
      margin-bottom: none; 
      margin: 13px 0;  
    }
  }

  #chatgpt-question {
    line-height: 21px;
    border: solid 1px var(--gray-dd); 
    padding: 5px; 
  }

  #chatgpt-answer {
    line-height: 21px;
  }

  .qkn-chatgpt-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 13px; 

    .chat-gpt-modal-ops-buttons {
      margin-top: 5px; 
      align-items: end;
      display: flex;
      justify-content: flex-end;
      flex-basis: 50%;

      .btn {
        margin: 3px;
        border: solid 1px var(--gray-ee);
        padding: 5px;
        border-radius: 5px;
      }

      .btn:hover {
        margin: 3px;
        background: var(--white);
        border: solid 1px var(--gray-ee);
      }

      .chatgpt-ask-button {
        background: var(--qkn-mid-blue-background);
        font-weight: 600;
        color: var(--qkn-blurple);
        border: solid 1px var(--qkn-fi-flow-blue);
        cursor: pointer;  
      }

      .chatgpt-modal-close {
        font-weight: 600; 
        cursor: pointer; 
      }

      .btn.chatgpt-ask-button:hover {
        background: var(--white);
        font-weight: 600;
        border: solid 1px var(--qkn-blurple);
        color: var(--qkn-blurple);
      }

    }

    .chat-gpt-voting-area {
      text-align: left;
      flex-basis: 50%;

      .chat-gpt-voting-header {
        margin-left: 5px;
        font-weight: 600;
        display: none;
      }

      .qkn-chatgpt-feedback-msg {
        color: #333;
        font-weight: bold; 
      }

      .chat-gpt-voting-buttons img.chat-gpt-voting-image {
        height: 48px;
        display: none;
      }
    }
  }

  .form-item-chatgpt-question {
    margin-top: 5px; 
    label {
      font-weight: 600; 
    }

    .chatgpt-question {
      margin-top: 5px; 

      textarea {
        width: 100%; 
        font-family: 'Haffer', sans-serif;
      }
    }
  }

  p.qkn-chatgpt-legaleze {
    margin-top: 13px; 
    font-size: 9pt;
    margin-left: 13px;
    font-weight: 300;
    text-align: left;
  }

  .modal-title {
    font-size: 17px;
    font-weight: 600;
  }

  .modal-content {
    .modal-header {
      position: relative;
      .modal-title {
        position: absolute;
      }
    }
  }
}

@media (min-width: 992px) {
  .support-footer .contact-support-methods .col-sm-4.support-page-last-tile {
    border-right: unset !important;  /* 1px solid rgba(47,62,168,.3); */
  }
}


