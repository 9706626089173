.static-form {
  padding: 0px 20px;

  .breadcrumbs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 110px;

    @include bp(sm-md) {
      max-width: 1280px;
      margin: 0 auto;
    }

    a {
      color: var(--black);
      text-decoration: underline;
    }

    span {
      padding: 0px 5px;
    }

    p {
      font-weight: 600;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: 650px;
    margin: 0 auto;

    .heading {
      color: var(--qkn-medium-gray);
  
      h2 {
        letter-spacing: 0px;
        padding-bottom: 30px;
        margin: 0;
        @include bp(sm-md) {
          letter-spacing: -2px;
        }
      }

      p {
        line-height: 25px;
      }
    }

    .sms-form {
      max-width: 426px;
      padding-top: 40px;

      .field-grp {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;

        label {
          font-size: 14px;
          line-height: 21px;
          padding-bottom: 5px;
          font-weight: 600;
        }

        input {
          padding: 12px 16px;
          border: 1px solid var(--qkn-border-gray1);
          border-radius: 4px;

          &::placeholder {
            color: var(--qkn-light-gray-opaque);
          }
        }
      }

      .submit-area {
        .cta-btn {
          width: 100%;
          // pointer-events: none;
          background-color: var(--qkn-medium-gray4);
        
          &:hover {
            background-color: var(--qkn-medium-gray4);
            color: var(--qkn-light-gray-opaque);
          }
        }

        a {
          cursor: not-allowed;
          color: var(--qkn-light-gray-opaque);
          &:hover {
            color: var(--qkn-light-gray-opaque);
          }
        }

        .error {
          padding: 10px 0px 30px 0px;
          color: var(--qkn-red);
        }
      }

      .terms-text {
        display: -webkit-inline-box;
        width: 100%;

        @include bp(sm-md) {
          width: 650px;
          margin: 0 auto;
        }

        p.note {
          color: var(--qkn-light-gray-opaque);
          font-size: 14px;
          line-height: 21px;
          width: calc(100% - 24px);
          padding-bottom: 30px;
        }
      }
    }
  }
}