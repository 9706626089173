.image-accordion {
  padding: 24px 0 24px 0;

  @include bp(md) {
    padding: 40px 0 40px 0;
  }
}

.accordion-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .accordion-box-1 {
    width: 464px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 35px;

    @include bp(1150px, max) {
      @include bp(md) {
        width: 342px;
      }
    }

    @include bp(md, max) {
      max-width: 640px;
      width: 90vw;
    }

    .option {
      cursor: pointer;

      @include bp(md) {
        &:not(:last-child) {
          padding-bottom: 48px;
        }
      }

      &.active {
        .border-opened {
          display: block;
        }

        .border-closed {
          display: none;
        }

        .option-title {
          margin-bottom: 24px;
        }

        .desktop-border {
          background-color: var(--qkn-blurple, #471CFF);
        }

        .option-title-text {
          color: var(--qkn-blurple, #471CFF);
        }

        .title-arrow-down {
          display: none;
        }

        .title-arrow-up {
          display: block !important;
        }

        .option-text {
          display: block !important;
        }
      }
    }

    .option-container {
      display: flex;
      flex-direction: row;
      height: 100%;
      position: relative;

      .desktop-border {
        display: none;
        background-color: var(--qkn-medium-gray2);
        width: 4px;
        border-radius: $border-radius-default;
        height: 100%;
        position: absolute;

        @include bp(md) {
          display: inline;
        }
      }

      .option-border-desktop {
        @include bp(md, max) {
          display: none !important;
        }
      }

      .option-border-mobile {

        @include bp(md) {
          display: none !important;
        }
      }

      .border-opened {
        display: none;
      }


      .option-text-container {
        padding-left: 0px;
        width: 100%;

        @include bp(md) {
          position: relative;
          width: 100%;
          display: flex;
          padding-left: 32px;
          flex-direction: column;
        }

        .option-title {
          color: var(--base-grey-100, #18181f);
          font-size: 26px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.26px;
          display: flex;
          flex-direction: column;

          .option-title-text {
            display: flex;
            justify-content: space-between;
            margin: 24px 0;

            @include bp(md) {
              margin: 0;
            }

            .title-arrow-down {
              margin-right: 12px;

              @include bp(md) {
                display: none !important;
              }
            }

            .title-arrow-up {
              display: none;
              margin-right: 12px;

              @include bp(md) {
                display: none !important;
              }
            }
          }
        }

        .option-text {
          display: none;
          color: var(--base-grey-200, #494949);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;

          .image-mobile-container {
            display: none;
            margin-top: 24px;

            @include bp(md, max) {
              display: block;
            }

            .image-mobile {
              width: 100%;
              height: auto;
              padding-bottom: 24px;

              @include bp(md) {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .accordion-box-2 {
    width: 580px;
    height: auto;
    border-radius: $border-radius-default;

    @include bp(md, max) {
      display: none;
    }

    .box-2-image {
      width: 100%;
      height: auto;
      display: none;

      &.active {
        display: block;
      }
    }
  }
}