// Colors variables
:root {
    --black: #000;
    --qkn-red: #EB0130;
    // #EB0130 with 0.2 opacity
    --qkn-light-red: rgba(235, 1, 48, .2);
    --qkn-red-background: #FBCCD6;
    --qkn-strike-red: #FF5A43;
    --qkn-blurple: #471CFF;
    --qkn-blurple2: #3F1CD4;
    --qkn-light-blurple: rgba(140, 113, 255, .2);
    --qkn-bright-blurple: #6A47FF;
    --qkn-dark-purple: #3317AA;
    --qkn-mid-blue-background: #DEE5FF;
    --qkn-light-blue: #F3F8FD;
    --qkn-light-gray: #494949;
    // 494949 with 0.8 opacity
    --qkn-light-gray-opaque: rgb(73, 73, 73, .8);
    --qkn-medium-gray: #18181F;
    --qkn-medium-gray2: #EAECF7;
    --qkn-medium-gray3: #F1F4FD;
    --qkn-medium-gray4: #E9E9E9;
    --qkn-border-gray: #ECEDF1;
    --qkn-border-gray1: #DBDEEE;
    --dark-red: #c20631;
    --error-red: #eb0230;
    --blue: #1f74bb;
    --qkn-fi-flow-blue: #9eb1f7;
    --light-blue: #EEFBFF;
    --white: #ffffff;
    --light-white: rgba(255, 255, 255, .2);
    --support-banner-green: #D8F3E3;
    --support-banner-red: #FBCCD6;
    --weird-neon-green: #E6FAAD;
    --dark-neon-green: #ACCC4D;

    // Product colors
    --light-purple: #a87ea7;
    --light-purple2: #DBD3FF;
    --dark-purple: #9f80a6;
    --deluxe-blue: #3f9edc;
    --light-green: #81be57;
    --dark-green: #53924f;
    --wild-willow-green: #B3C65A;
    --promo-red: #B12834;
    --simplifi-banner-blue: #283E9F;
    --star-yellow: #FFE629;

    // Grays
    --gray-33: #333;
    --gray-51: #515151;
    --gray-63: #636363;
    --gray-66: #666;
    --gray-80: #808080;
    --gray-85: #858585;
    --gray-99: #999;
    --gray-b3: #b3b3b3;
    --gray-dd: #ddd;
    --gray-e6: #e6e6e6;
    --gray-ee: #eee;
    --gray-ea: #eaeaea;
    --gray-f0: #f0f0f0;
    --gray-f2: #f2f2f2;
    --gray-f3: #f3f3f3;
    --lightGray: #C8C8C8;
    --doveGray: #333;
    --darkGray: #18181F;
    --card-gray: #F0F5FA;
    --bright-gray: #EAECF7;
    --lavender-blue: #BAC9FF;
    --eerie-black: #18181F;
    --eerie-black-opacity: rgba(24, 24, 31, 0.6);
    --light-steel-blue: #DEE5FF;
    --lavender-mist: #F3F1FF;

    // Support
    --card-green: #D8F3E3;
    --light-purple2-40p: #DBD3FF66
}

.qkn-mid-blue-background {
  background-color: var(--qkn-mid-blue-background);
  fill: var(--qkn-mid-blue-background);
}
.qkn-lavender-blue-background {
  background-color: var(--lavender-blue);
  fill: var(--lavender-blue);
}
.card-gray,
.card-grey, //mispelling in some of the components in contentful 
.qkn-card-gray-background {
  background-color: var(--card-gray);
  fill: var(--card-gray);
}
.white,
.white-background,
.qkn-white-background {
  background-color: var(--white);
  fill: var(--white);
}
.light-blue-background {
  background-color: var(--light-blue);
  fill: var(--light-blue);
}
.light-grey-background {
  background-color: var(--light-gray);
  fill: var(--light-gray);
}
.qkn-blue-background {
  background-color: var(--qkn-blurple);
  fill: var(--qkn-blurple);
}
.qkn-red-background {
  background-color: var(--qkn-red);
  fill: var(--qkn-red);
}
.qkn-light-blue,
.qkn-lightblue-background {
  background-color: var(--qkn-light-blue);
  fill: var(--qkn-light-blue);
}
.qkn-dark-purple-background {
  background-color: var(--qkn-dark-purple);
  fill: var(--qkn-dark-purple);
}
.qkn-blurple-background {
  background-color: var(--qkn-blurple);
  fill: var(--qkn-blurple);
}
.qkn-red {
  color: var(--qkn-red);
  fill: var(--qkn-red);
}
.qkn-blue {
  color: var(--qkn-blurple);
  fill: var(--qkn-blurple);
}
.qkn-blurple {
  color: var(--qkn-blurple);
  fill: var(--qkn-blurple);
}
.qkn-white {
  color: var(--white);
  fill: var(--white);
}
.qkn-base-grey {
  color: var(--darkGray);
  fill: var(--darkGray);
}

.qkn-support-banner-green {
  background-color: var(--support-banner-green);
}
.qkn-support-banner-red {
  background-color: var(--support-banner-red);
}

.greyscale {
  filter: grayscale(100%) opacity(0.5);
}

.teal-color-scheme {
  background-color: #006178;
  color:  #ffffff;
}