.search-results-wrapper {

  &.qkn-opt-search-results-wrap {
    max-width: 100%; 
    @include bp(sm-md) {
      max-width: 75%; 
    }

    margin: 0 auto; 
    padding: 0 21px 21px 21px; 

    .search-heading-wrap {
      margin-top: unset; 
    }

    .search-area {
      padding: 0 8px; // 21px 8px;
    }
  }

  .search-area {
    .search-results-area-wrap {
      &.qkn-opt-search-area-wrap {
        flex-basis: 100%; 
      }
    }
  }

  .search-results {
    &.qkn-opt-search {
      min-height: unset; 
    }
  }

  .qkn-opt-search-area-wrap {
    .qkn-opt-search {
      .search-result-item {
        color: var(--qkn-light-gray);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; 
      }
    }
  }

  .qkn-opt-get-in-touch-area {
    display: none; 
    h2 {
      font-size: 17px;
      font-weight: 600; 
    }
  
    .qkn-opt-get-in-touch-tiles {
      display: flex;
      flex-direction: column;
      @include bp(md) {
        flex-direction: row;
      }
      justify-content: center;
      gap: 21px;
  
      .qkn-opt-in-touch-tile {
        display: flex;
        flex-basis: 45%;
        gap: 21px;
        border-radius: 16px;
        
        &.recommended {
          border: 2px solid var(--qkn-blurple);
          position: relative; 

          .recommended-badge {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
              
            position: absolute;
            width: 140px;
            top: -16px;
            text-align: center;
            border: solid 2px var(--qkn-blurple);
            border-radius: 6px;
            background: var(--white);
            padding: 5px;
            left: calc(50% - 75px);
            font-size: 14px; 
            line-height: 120%; 
            color: var(--qkn-blurple); 

            .item-badge-image {
              margin-right: 5px; 
            }
          }
        }

        background: var(--qkn-light-blue);
  
        padding: 24px 24px 24px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
  
        h3 {
          font-size: 23px; 
        }
  
        .qkn-opt-contact-msg {
          color: var(--qkn-light-gray);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          .qkn-opt-wait-time-duration {
            color: #32A45F;
          }
        }

        .qkn-opt-chat-now-btn {
          display: flex;
          align-items: center;
          height: 50px;
          padding: 12px 24px;
          border-radius: 400px;
          background: var(--qkn-blurple);
          color: var(--white); 
        }
      }
    }
  }
  
  .other-help-area {
    max-width: 880px; 
    margin: 0 auto; 
  
    h2 {
      font-size: 30px; 
    }
    
    .other-help-tiles {
      display: flex; 

      flex-direction: column;
      @include bp(md) {
        flex-direction: row;  
      }
       
      justify-content: space-between;
      gap: 16px; 
  
      .other-help-tile {
        min-height: 170px; 
  
        .other-help-tile-link {
          display: flex;
          flex-direction: column;
          padding: 16px 24px 24px 24px;
          justify-content: space-between;
          gap: 13px; 
          align-items: flex-start;
          border-radius: 16px;
          background: var(--qkn-light-blue);
          color: unset; 
          height: 100%; 
  
          &.variation-3 {
            background: unset; 
            border-radius: 16px;
            border: 1px solid var(--qkn-medium-gray2);
          }

          h3 {
            color: var(--qkn-medium-gray);
            font-size: 21px;
          }
  
          p {
            color: var(--qkn-light-gray); 
            font-size: 16px; 
          }
        }
      }
    }
  }
}
