section.table {
    overflow: auto;

    table {
        border: 1px solid var(--qkn-border-gray);
        border-radius: 16px;
        border-collapse: separate;
        margin: auto;
        max-width: 944px;

        tbody {
            tr td {
                position: relative;
                padding: 12px;
                @include bp(md) {
                    padding: 16px;
                }

                .subtitle {
                    cursor: help;

                    .tooltip {
                        position: absolute;
                        padding: 8px 12px;
                        border-radius: 4px;
                        background: var(--light-purple2);
                        max-width: 250px;
                        top: 3em;
                        left: 0;
                        z-index: 2;
                        text-align: center;
                    }
                }

                .row-chevron {
                    display: none;
                    float: left;
                    margin-right: 0.5em;
                    margin-top: 0.25em;
                    transition-duration: 0.5s;
                    transition-property: transform;
                    transform: rotateX(150deg);
                }

                ol, ul {
                    list-style-position: inside;

                    li p {
                        display: inline;
                    }
                }
                ol {
                    list-style-type: decimal;
                }
                ul {
                    list-style-type: disc;
                }

                code {
                    font-family: 'Courier New', monospace;
                    color: #333;
                    white-space: pre;
                }

                // Mobile mode needs to suppress the first column
                &:nth-child(1) {
                    display: none;
                }

                // Price component explodes horiziontally without this */
                .price-section.stacked {
                    width: 0;
                }
            }

            tr.desktop {
                display: none;
            }

            &.collapsed tr td .row-chevron {
                transform: rotateX(0deg);
            }

            &[data-super-id] /* (selecting any TBODY which contains subrows) */ {
                tr.mobile.primary td:nth-child(2),
                tr td:first-child {
                    cursor: pointer;
                    .row-chevron {
                        display: block;
                    }
                }
            }

            tr {
                &.background-white                    td { background-color: var(--white); }
                &.background-qkn-light-blue           td { background-color: var(--qkn-light-blue); }
                &.background-card-gray                td { background-color: var(--card-gray); }
                &.background-qkn-mid-blue-background  td { background-color: var(--qkn-mid-blue-background); }
            }
        }

        // Special column border highlight feature
        &[data-border-column = "0"] tbody tr td:nth-child(1),
        &[data-border-column = "1"] tbody tr td:nth-child(2),
        &[data-border-column = "2"] tbody tr td:nth-child(3),
        &[data-border-column = "3"] tbody tr td:nth-child(4) {
            border: 3px solid transparent;
            border-top: 0;
            border-bottom: 0;
        }
        &[data-border-column = "0"] tbody:first-child tr:first-child td:nth-child(1),
        &[data-border-column = "1"] tbody:first-child tr:first-child td:nth-child(2),
        &[data-border-column = "2"] tbody:first-child tr:first-child td:nth-child(3),
        &[data-border-column = "3"] tbody:first-child tr:first-child td:nth-child(4) {
            border-top: 3px solid transparent;
            border-radius: 6px 6px 0 0;
        }
        &[data-border-column = "0"] tbody:last-child tr.last:not(.mobile.primary) td:nth-child(1),
        &[data-border-column = "1"] tbody:last-child tr.last:not(.mobile.primary) td:nth-child(2),
        &[data-border-column = "2"] tbody:last-child tr.last:not(.mobile.primary) td:nth-child(3),
        &[data-border-column = "3"] tbody:last-child tr.last:not(.mobile.primary) td:nth-child(4) {
            border-bottom: 3px solid transparent;
            border-radius: 0 0 6px 6px;
        }

        // Row border feature
        tbody:not(:last-child) tr:not(.mobile.primary) td {
            box-shadow: inset 0 -1px 0 0 var(--white);
        }
        &.white {
            tbody:not(:last-child) tr:not(.mobile.primary) td {
                box-shadow: inset 0 -1px 0 0 var(--qkn-border-gray);
            }
        }

        // Column width feature
        $columnSpreadModes: equal,null;
        @each $columnSpreadMode in $columnSpreadModes {
            &[data-column-spread="#{$columnSpreadMode}"] {
                @for $numberOfColumns from 2 through 4 {
                    tbody tr {
                        &[data-column-count="#{$numberOfColumns}"] {
                            @for $colNo from 1 through $numberOfColumns {
                                $width: auto;
                                $mobileWidth: auto;
                                @if $columnSpreadMode == equal {
                                    $width: percentage(calc(1 / $numberOfColumns));
                                    $mobileWidth: percentage(calc(1 / ($numberOfColumns - 1))); // Mobile mode excludes the first column, so recalculate based on one less column here.
                                } @else {
                                    // Request for new spread modes anticipated
                                    // Add new @elseif so you can calculate any column width at any spread mode
                                }
                                td:nth-child(#{$colNo}) {
                                    width: $width;
                                }
                                &.mobile td:nth-child(#{$colNo}) {
                                    width: $mobileWidth;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include bp(sm-md) { // Desktop Only
            tbody tr {
                td {
                    border-color: var(--qkn-blurple) !important;

                    // Show first column
                    &:nth-child(1) {
                        display: table-cell;
                    }
                }

                &.desktop {
                    display: table-row;
                }

                &.mobile {
                    display: none;
                }
            }
        }
    }
}