.support-banner {
  position: relative;
  margin: 0 21px; 
  h1, h2, h3, h4, h5 {
    margin: 5px; 
  }
  .support-banner-closer {
    position: absolute;
    margin-right: 11px;
    margin-top: 5px;
    right: 0px;
    cursor: pointer;
  }
}
