section.callout-container {
  box-sizing: border-box;
  width: 100%;

  .ta-left .content {
    align-items: left;
    justify-content: left;

    .banner-text {
      text-align: left;

      .subhead {
        text-align: left;
      }
    }
  }

  .content {
    align-items: center;
    justify-content: center;
    padding: 0 24px;

    @include bp(md) {
      padding: 0 100px;
    }

    .banner-text {
      max-width: 900px;
      text-align: center;

      .pre-title {
        font-size: 21px;
        margin-bottom: 16px;
      }

      .subhead {
        padding-top: 19px;
        text-align: center;
        font-size: 19px;
        line-height: 1.25;
      }
    }
  }

  .countdown-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    max-width: 463px;
    min-width: 314px;
    padding: 12px 15px 11px 12px;
    border-radius: 16px;
    border: 1px solid var(--qkn-mid-blue-background);
    margin: 20px 0px;

    .time-container {
      min-width: 106px;
      margin-right: 44px;

      @include bp(md-lg, max) {
        margin-right: 0px;
      }
    }

    .time-unit {
      border-radius: 4px;
      margin: 2px;
      font-size: 24px;
      font-style: normal;
      width: 53%;
      height: 74px;
      flex-shrink: 0;

      @include bp(md-lg, max) {
        border-radius: 4px;
        margin: 2px;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        width: 49px;
        height: 48px;
      }
    }

    .value {
      font-weight: bold;
      font-size: 30px;
      border-radius: 10px 10px 0px 0px;
      width: 100%;
      text-align: center;
      letter-spacing: -0, 9x;

      @include bp(md-lg, max) {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.72px;
      }
    }

    .label {
      font-size: 12px;
      border-radius: 0px 0px 10px 10px;
      width: 100%;

      @include bp(md-lg, max) {
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
      }
    }

    .highlight-text,
    .timer-text {
      font-size: 23px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.23px;

      @include bp(md-lg, max) {
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        width: 176px;
      }
    }
  }
}