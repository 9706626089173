@keyframes marquee {
  0% {
    transform: translateX(5%);
  }

  100% {
    transform: translateX(-100%);
  }
}


.bannerv2 {
  &.banner-padding {
    padding: 10px 0px;
  }
  &.banner-padding.top-of-page {
    padding: 18px 0px 0px 0px;
  }

  .banner {
    overflow: hidden;
    border-radius: 12px;
    min-height: 54px;
    display: flex;
    align-items: center;

    &[data-parentlink] {
      cursor: pointer;
    }

    &.centered {
      justify-content: flex-start;

      @include bp(sm-md) {
        justify-content: center;
      }
    }

    .banner-content {
      display: flex;
      flex-direction: column;

      @include bp(sm-md) {
        flex-direction: row;
        align-items: center;
      }

      &.centered {
        justify-content: flex-start;

        @include bp(sm-md) {
          justify-content: center;
        }
      }

      .banner-text {
        line-height: 140%;

        a {
          font-weight: normal;
          color: var(--qkn-blurple);
          text-decoration: underline;
        }
      }

      img {
        width: 78px;
        height: 44px;
        margin-right: 12px;
        z-index: 2;
      }
    }

    &.normal {
      padding: 12px 12px;

      @include bp(sm-md) {
        padding: 8px 8px;
      }

      .image-container {
        display: none; //"none" to avoid the image pop-up before JS loads, managed by JS in Countdown Timer
      }

      img {
        margin-bottom: 12px;

        @include bp(sm-md) {
          margin-bottom: 0px;
        }
      }
    }

    &.scrolling {
      padding: 4px 4px;

      @include bp(sm-md) {
        padding: 8px 8px;
      }

      .banner-content {
        flex-direction: row;
        align-items: center;

        img {
          box-shadow: 12px 0 15px -4px var(--light-steel-blue), -12px 0 8px -4px var(--light-steel-blue);
        }
      }

      .banner-text {
        white-space: nowrap;
        width: fit-content;
        transform: translateX(100%);
        animation: marquee 20s linear infinite;

        @include bp(sm-md) {
          animation: marquee 15s linear infinite;
        }


        &:hover {
          -webkit-animation-play-state: paused;
          animation-play-state: paused;
        }
      }

      &:hover {
        .banner-text {
          -webkit-animation-play-state: paused;
          animation-play-state: paused;
        }
      }
    }
  }
}