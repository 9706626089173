$radius-btt: 44px;
$corner-position-btt: 20px;
.btt-contain {
  display: none;
  position: fixed;
  right: $corner-position-btt;
  bottom: $corner-position-btt;
  cursor: pointer;
  border-radius: $radius-btt;
  z-index: 20;

  .btt-btn {
    color: var(--gray-99);
    box-sizing: border-box;
    position: relative;
    display: block;
    width: $radius-btt;
    height: $radius-btt;
    border: 2px solid;
    border-radius: $radius-btt;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--qkn-blurple);
        border: 0;
      }
    }
    &:active {
      background-color: var(--qkn-blurple);
      border: 0;
    }
  }

  &::after {
    content: "";
    pointer-events: none;
    color: var(--gray-99);
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: calc($radius-btt * 0.3);
    height: calc($radius-btt * 0.3);
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
    left: calc($radius-btt * 0.34);
    bottom: calc($radius-btt * 0.26);
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover::after {
      color: var(--white);
    }
  }
  &:active::after {
    color: var(--white);
  }
}