div.rich-text,
section.rich-text {
    color: var(--darkGray);
    line-height: 1.5em;

    h1, h2, h3, h4, h5, h6 {
        margin: 0.5em 0;
    }

    :not(button) > a:hover {
        background: var(--qkn-light-blurple);
    }

    p {
        margin-bottom: 1em;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: $border-radius-default;
    }

    hr {
        border: 0;
        border-top: 1px solid var(--lightGray);
        margin: 40px 0;
    }

    ul, ol {
        li {
            margin-bottom: 13px;
        }
    }
    ul {
        list-style: disc;
        margin-left: 1em;

        li::marker {
            color: var(--qkn-blurple);
        }
    }
    ol {
        list-style: decimal;
        margin-left: 1.5em;
    }

    span, div {
        &[data-text-strikethrough] {
            text-decoration: line-through;
        }

        &[data-text-color="qkn-blurple"] {
            color: var(--qkn-blurple);
        }
        &[data-text-color="qkn-red"] {
            color: var(--qkn-red);
        }
        &[data-text-color="black"] {
            color: var(--black);
        }

        &[data-paragraph-alignment="left"] {
            text-align: left;
        }
        &[data-paragraph-alignment="right"] {
            text-align: right;
        }
        &[data-paragraph-alignment="center"] {
            text-align: center;
        }

        &[data-bullet-style="check"] {
            ul {
                list-style: none;
                margin-left: 0;
                li {
                    background: url("/assets/images/list-item-check.svg") no-repeat left 5px;
                    background-size: 1em;
                    padding-left: 1.5em;
                }
            }
        }
    }

    section.table p {
        margin: 0;
    }

    .price-section {
        width: max-content;
    }

    .subtitle {
        cursor: help;
        position: relative;

        .tooltip {
            position: absolute;
            padding: 8px 12px;
            border-radius: 4px;
            background: var(--light-blue);
            width: max-content;
            top: -50%;
            left: 1.5em;
            z-index: 2;
            text-align: center;
        }
    }
}

div.rich-text {
    p,
    ul li, ol li,
    hr,
    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }
}